/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Input, Button, Menu, Dropdown, DatePicker, Tooltip, Select, Col, Switch } from 'antd';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { CaretDownOutlined } from '@ant-design/icons';
import { useIntercom } from 'react-use-intercom';
import moment from 'moment';
import PropTypes from 'prop-types';
import utils from '../../../Draft/utils';

import SettingsSmallIcon from 'components/Icons/SettingsSmallIcon';
import CalendarIcon from 'components/Icons/CalendarIcon';
import ResetIcon from 'components/Icons/ResetIcon';
import InfoIcon from 'components/Icons/InfoIcon';
import CloseIcon from 'components/Icons/CloseIcon';
import Languages from 'constants/languages';
import { ContactOptions, DEFAULT_CONTACTS_OPTIONS } from 'constants/index';
import useSuspiciousLink from 'hooks/useSuspiciousLink';

import './SettingsModal.scss';

const { Option } = Select;

const SettingsModal = ({
  user,
  proposal,
  saveProposal,
  configText,
  updateLoaderState,
  scrollToSection,
  setProp,
  propRef,
  path,
}) => {
  const { trackEvent } = useIntercom();
  const { checkSuspiciousUrl } = useSuspiciousLink({ proposalId: proposal._id });

  const [optionsVisible, setOptionVisibility] = useState(false);
  const [clientEmail, setClientEmail] = useState('');
  const [numberStatus, setNumberStatus] = useState('');
  const [emailStatus, setEmailStatus] = useState('');
  const [redirectTo, setRedirectTo] = useState('');
  const [redirectToStatus, setRedirectToStatus] = useState('');
  const [redirectAfter, setRedirectAfter] = useState();
  const [contactPersonEmailStatus, setContactPersonEmailStatus] = useState('');
  const [whatsappNumberStatus, setWhatsappNumberStatus] = useState('');
  const [signatureEmailStatus, setSignatureEmailStatus] = useState(['']);
  const [contactNumber, setContactNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [changedDate, setChangedDate] = useState(false);
  const [expiryMessage, setExpiryMessage] = useState('');
  const [defaultExpiryMessage, setDefaultExpiryMessage] = useState('');
  const [proposalLanguage, setLanguage] = useState('');
  const [signatureEmails, setSignatureEmails] = useState([]);
  const [isYearView, setIsYearView] = useState(false);
  const [proposalContactOptions, setProposalContactOptions] = useState([
    ...DEFAULT_CONTACTS_OPTIONS,
  ]);
  const [changedKeys, setChangedKeys] = useState([]);
  const [canUseAI, setCanUseAI] = useState(false);
  const [enableAITool, setEnableAITool] = useState(false);

  const getExpiryDate = () => {
    if (proposal?.expiryDate) {
      return moment(proposal.expiryDate);
    }
    return null;
  };

  useEffect(() => {
    let nameHtmlClean;
    const {
      draft: { header },
      client,
      language,
      contactnumber,
      signatureEmails,
      contactOptions,
    } = proposal;

    if (proposalLanguage === 'english') {
      setCanUseAI(true);
    }

    setLanguage(language?.toLowerCase());
    setEnableAITool(proposal?.enableAITool);

    if (header) {
      let nameHtml = header.name;
      if (header.rawname) {
        const names = convertFromRaw(header.rawname);
        nameHtml = stateToHTML(names);
      }
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = nameHtml;
      nameHtmlClean = tempDiv.textContent;
    } else {
      nameHtmlClean = client?.contact || '';
    }

    setClientEmail(client?.email);
    setContactNumber(contactnumber);
    setRedirectTo(proposal?.proposalRedirectTo || proposal?.redirectTo);
    setRedirectAfter(
      proposal?.proposalRedirectTo ? proposal?.proposalRedirectAfter : proposal?.redirectAfter
    );
    let _expiryDate = getExpiryDate();

    setExpiryDate(_expiryDate);
    setExpiryMessage(
      proposal.expiryMessage ||
        `This proposal has expired. Contact ${nameHtmlClean.split(' ')[0]} for more information.`
    );
    setDefaultExpiryMessage(
      `This proposal has expired. Contact ${nameHtmlClean.split(' ')[0]} for more information.`
    );
    setSignatureEmails(signatureEmails || []);

    if (!contactOptions.length) {
      setProposalContactOptions((prev) => {
        // DEFAULT_CONTACTS_OPTIONS
        const newData = prev.map((obj) => {
          let value;
          if (obj.option === 'email') {
            value = user?.emails[0]?.address;
          } else if (obj.option === 'call') {
            value = user?.profile?.contactnumber;
          } else if (obj.option === 'whatsapp') {
            value = user?.profile?.whatsappNumber;
          }

          return {
            ...obj,
            value: value || '',
            enabled: !!value,
          };
        });

        // save the contactOptions for first time
        saveProposal({ contactOptions: newData, editEvent: true });

        return newData;
      });
    } else {
      setProposalContactOptions(contactOptions);
    }
  }, []);

  const isTemplate = () => {
    if (path === '/t/:tid') {
      return true;
    }
    return false;
  };

  const onClickMenu = () => {
    setOptionVisibility(true);
    setNumberStatus('');
    setWhatsappNumberStatus('');
    setEmailStatus('');
    setSignatureEmailStatus(new Array(signatureEmails.length).fill(''));
  };

  const removeSignatureEmail = (signatureEmailIndex) => {
    const tempSignatureEmails = [...signatureEmails] || [];
    tempSignatureEmails.splice(signatureEmailIndex, 1);
    setSignatureEmails(tempSignatureEmails);

    // remove email status
    const tempSignatureEmailStatus = [...signatureEmailStatus] || [];
    tempSignatureEmailStatus.splice(signatureEmailIndex, 1);
    setSignatureEmailStatus(tempSignatureEmailStatus);
  };

  const addSignatureEmail = () => {
    const tempSignatureEmails = [...signatureEmails] || [];
    tempSignatureEmails.push('');
    setSignatureEmails(tempSignatureEmails);
  };

  const resetSettings = () => {
    setExpiryDate(null);
    setClientEmail(null);
    setContactNumber(null);
    setRedirectTo('');
    setRedirectAfter('');
    setContactPersonEmailStatus('');
    setRedirectToStatus('');
    setNumberStatus('');
    setWhatsappNumberStatus('');
    setEmailStatus('');
    setExpiryMessage(defaultExpiryMessage);
    setProposalContactOptions([...DEFAULT_CONTACTS_OPTIONS]);
    setEnableAITool(proposal?.enableAITool);
    setLanguage(proposal?.language);

    let tempClient = Object.assign({}, proposal.client);
    tempClient['email'] = '';

    saveProposal({
      expiryMessage: '',
      expiryDate: null,
      contactnumber: null,
      client: tempClient,
      contactOptions: [...DEFAULT_CONTACTS_OPTIONS],
      editEvent: true,
      proposalRedirectTo: null,
      proposalRedirectAfter: null,
      redirectTo: null,
      redirectAfter: null,
      enableAITool,
      proposalLanguage,
    });
  };

  const isEmail = (email) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !!email && emailRegex.test(email);
  };
  const saveSettings = () => {
    if (expiryDate || expiryMessage || clientEmail || proposalLanguage || enableAITool) {
      setNumberStatus('');
      setWhatsappNumberStatus('');
      setEmailStatus('');
      setSignatureEmailStatus(new Array(signatureEmails.length).fill(''));
      setContactPersonEmailStatus('');
      window.ga('send', 'event', 'edit', 'edit-expiry-date', window.location.href);
      if (!proposal?.wixProposalId) {
        trackEvent('expiry-date-updated');
      }
      const signatureEmailsStat = new Array(signatureEmails.length).fill('');
      let error = false;
      // Validate contact number
      if (
        !!proposalContactOptions?.[1]?.value &&
        !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(proposalContactOptions[1].value)
      ) {
        error = true;
        setNumberStatus('error');
      }
      // Validate WhatsApp number
      if (
        !!proposalContactOptions?.[2]?.value &&
        !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(proposalContactOptions[2].value)
      ) {
        error = true;
        setWhatsappNumberStatus('error');
      }
  
      // Validate email addresses
      if (
        !!proposalContactOptions?.[0]?.value &&
        !isEmail(proposalContactOptions[0].value)
      ) {
        error = true;
        setEmailStatus('error');
      }
  
      // Validate redirect URL
      if (
        (redirectTo?.length > 0 || !!redirectAfter) &&
        !/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,50}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
          redirectTo
        )
      ) {
        error = true;
        setRedirectToStatus('error');
      }
  
      // Validate client email
      if (
        !!clientEmail &&
        !isEmail(clientEmail)
      ) {
        error = true;
        setContactPersonEmailStatus('error');
      }
  
      // Validate signature emails
      const filteredSignatureEmails = [];
      signatureEmails.forEach((email, emailIndex) => {
        if (!isEmail(email)) {
          error = true;
          signatureEmailsStat[emailIndex] = 'error';
        } else if (!filteredSignatureEmails.includes(email)) {
          signatureEmailsStat[emailIndex] = '';
          filteredSignatureEmails.push(email);
        }
      });
  
      setSignatureEmailStatus(signatureEmailsStat);
      setSignatureEmails(filteredSignatureEmails);
  
      if (!error) {
        setRedirectToStatus('');
        const _expiryDate = expiryDate && expiryDate !== '' ? moment(expiryDate).toDate() : null;
  
        if (changedKeys.includes('redirectTo')) {
          // if user changed redirectTo, check for spam
          setTimeout(() => {
            checkSuspiciousUrl(redirectTo.trim());
          }, 2000);
        }
  
        const updateData = {
          expiryMessage,
          signatureEmails: filteredSignatureEmails,
          expiryDate: _expiryDate,
          client: { ...proposal.client, email: clientEmail },
          language: proposalLanguage,
          contactnumber: contactNumber,
          proposalRedirectTo: (redirectTo || '').trim(),
          proposalRedirectAfter: !!redirectTo ? redirectAfter : null,
          contactOptions: proposalContactOptions,
          enableAITool,
          editEvent: true,
          ...(!redirectTo ? { redirectTo: null, redirectAfter: null } : {}),
        };
  
        if (!redirectTo) {
          setRedirectAfter('');
        }
  
        if (!isTemplate() && !proposal.importedProposal) {
          if (changedDate) {
            updateLoaderState(true);
            let termsExpiryDate = expiryDate && expiryDate !== '' ? expiryDate : 'NA';
            updateData['draft'] = {
              ...updateData.draft,
              terms: utils.getTerms({
                prop: proposal,
                configText,
                user,
                expiryDate: termsExpiryDate,
              }),
            };
            saveProposal(updateData, ({ data }) => {
              propRef.current.draft = { ...proposal.draft, terms: null };
              if (data) {
                setProp({ ...proposal, updateData });
                propRef.current.draft = { ...proposal.draft, terms: updateData?.draft?.terms };
                scrollToSection({ sectionId: 'terms' });
                updateLoaderState(false);
                saveProposal(updateData);
              }
            });
          }
        }
  
        saveProposal(updateData);
        setOptionVisibility(false);
      }
    }
  };

  const onChangeRedirectTo = (value) => {
    if (!value?.length || redirectAfter === '') {
      setRedirectAfter(0);

      if (!value?.length) {
        setRedirectToStatus('');
      }
    }
    setRedirectTo(value);
    setChangedKeys((p) => [...new Set([...p, 'redirectTo'])]);
  };

  const handleContactOptionsChange = (name, value) => {
    if (!name) {
      setProposalContactOptions((prev) => {
        const newdata = prev.map((contactOption) => ({
          ...contactOption,
          enabled: value.includes(contactOption.option),
        }));
        return newdata;
      });

      // NOTE; old code kept intentionally
      // setProposalContactOptions(
      //   proposalContactOptions?.map((contactOption) => {
      //     if (value.includes(contactOption.option)) {
      //       return { ...contactOption, enabled: true };
      //     } else {
      //       return { ...contactOption, enabled: false };
      //     }
      //   })
      // );
    } else {
      setProposalContactOptions(
        proposalContactOptions?.map((contactOption) => {
          if (name === contactOption.option) {
            return { ...contactOption, value: value };
          } else {
            return { ...contactOption };
          }
        })
      );
    }
  };

  const disabledDate = (current) => {
    if (isYearView) {
      // When in year view, do not disable any dates
      return false;
    }
    // Allow today's date to be selectable
    const today = moment().startOf('day');
    return current && current.startOf('day').isBefore(today);
  };

  const handlePanelChange = (value, mode) => {
    if (mode === 'year') {
      setIsYearView(true);
    } else {
      setIsYearView(false);
    }
  };

  const onExpiryDateChange = (value) => {
    setExpiryDate(value);
    setChangedDate(true);
  };

  const handleLanguageChange = (value) => {
    setLanguage(value);
    setEnableAITool(value === 'english' ? proposal?.enableAITool : false);
    setCanUseAI(value === 'english');
  };

  const options = (
    <>
      <div className="menu">
        <h3 className="settings-label">PROPOSAL EXPIRY INFORMATION</h3>
        <Menu className="settings-menu">
          <Menu.Item key="expiry-date" className="form-item">
            <label className="form-label">Proposal Expiry Date</label>
            <DatePicker
              bordered={false}
              suffixIcon={<CalendarIcon />}
              value={expiryDate}
              className="date-picker"
              allowClear
              disabledDate={disabledDate}
              placeholder="No Expiry"
              disabled={isTemplate()}
              onChange={onExpiryDateChange}
              onPanelChange={handlePanelChange}
              format={user?.profile?.dateFormat || 'DD/MM/YYYY'}
            />
          </Menu.Item>

          <Menu.Item key="expiry-message" className="form-item">
            <label className="form-label">Proposal Expiry Message</label>
            <Input
              value={expiryMessage}
              onChange={(e) => {
                setExpiryMessage(e.target.value);
              }}
            />
          </Menu.Item>

          <Menu.Item key="expiry-language" className="form-item">
            <label className="form-label">Proposal Language</label>
            <Select
              value={proposalLanguage}
              onChange={(value) => handleLanguageChange(value)}
              suffixIcon={<CaretDownOutlined color="red" />}>
              {Languages.map((language) => (
                <Option key={language.value} value={language.value}>
                  {language.text}
                </Option>
              ))}
            </Select>
          </Menu.Item>
        </Menu>
        <h3 className="settings-label">CONTACT INFORMATION</h3>
        <Menu className="settings-menu">
          <Menu.Item key="contact-person-email" className="form-item">
            <label className="form-label">
              <div>
                <span>Contact Person Email </span>
                <span className="optional-text">(Optional)</span>
              </div>
              <Tooltip
                title="This is for integrations only. No email will be sent automatically to your client."
                color="white">
                <InfoIcon />
              </Tooltip>
            </label>
            <Input
              value={clientEmail}
              onChange={(e) => setClientEmail(e.target.value)}
              className={`contact-person-email-${contactPersonEmailStatus}`}
              placeholder="example@email.com"
            />
          </Menu.Item>
        </Menu>
        <h3 className="settings-label">CONTACT BUTTONS INFORMATION</h3>
        <Menu className="settings-menu">
          <Menu.Item key="contact-options" className="form-item">
            <label className="form-label">Contact Buttons</label>
            <Select
              className="settings-global"
              value={
                proposalContactOptions
                  ?.filter((contactOption) => contactOption?.enabled)
                  ?.map((contactOption) => contactOption?.option) || []
              }
              onChange={(val) => handleContactOptionsChange('', val)}
              suffixIcon={<CaretDownOutlined color="red" />}
              mode="multiple"
              showArrow={true}>
              {ContactOptions.map((el) => (
                <Option key={el.value} value={el.value}>
                  {el.text}
                </Option>
              ))}
            </Select>
          </Menu.Item>

          {proposalContactOptions
            ?.filter((contactOption) => contactOption?.enabled)
            ?.map((contactOption) => (
              <Menu.Item key={`signature-${contactOption.option}`} className="form-item">
                <label className="form-label">
                  <span>
                    {contactOption.option === 'whatsapp' ? 'WhatsApp' : contactOption.option} Info
                  </span>
                  {contactOption.option !== 'email' && (
                    <Tooltip
                      title={
                        <>
                          Make sure to include the country code in the mobile number for <br />
                          {contactOption.option === 'call' ? 'phone calls.' : 'WhatsApp messages.'}
                        </>
                      }
                      color="white">
                      <InfoIcon />
                    </Tooltip>
                  )}
                </label>
                <Input
                  className={`${
                    contactOption.option === 'call'
                      ? `call-${numberStatus}`
                      : contactOption.option === 'whatsapp'
                      ? `whatsapp-${whatsappNumberStatus}`
                      : `email-${emailStatus}`
                  }`}
                  value={contactOption.value}
                  placeholder={
                    contactOption.option !== 'email' ? 'E.g. +12163547758' : 'example@email.com'
                  }
                  onChange={(e) => handleContactOptionsChange(contactOption.option, e.target.value)}
                />
              </Menu.Item>
            ))}
        </Menu>
        <h3 className="settings-label">SEND SIGNED PROPOSALS EMAILS</h3>
        <Menu className="settings-menu">
          <Menu.Item key="signature-emails" className="form-item signed-email">
            <label className="form-label">
              <span>Send Signed Proposals To</span>
              <Tooltip
                title="An email with the signed proposal (PDF) will be sent to your teammates' email addresses you added."
                color="white">
                <InfoIcon />
              </Tooltip>
            </label>

            <Col className="signature-emails-col">
              {signatureEmails.map((signatureEmail, emailIndex) => (
                <Input
                  className={`signature-email-${signatureEmailStatus[emailIndex]}`}
                  // readOnly={signatureEmail}
                  key={emailIndex}
                  value={signatureEmail}
                  onChange={(e) => {
                    setSignatureEmails((prev) => {
                      const newEmails = [...prev];
                      newEmails[emailIndex] = e.target.value;
                      return newEmails;
                    });

                    // update the status
                    let status = isEmail(e.target.value) ? '' : 'error';
                    if (status !== signatureEmailStatus[emailIndex]) {
                      signatureEmailStatus[emailIndex] = status;
                      setSignatureEmailStatus(signatureEmailStatus);
                    }
                  }}
                  placeholder={'Enter Email Address(↵ to add)'}
                  suffix={<CloseIcon onClick={() => removeSignatureEmail(emailIndex)} />}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && isEmail(signatureEmail)) {
                      e.target.blur();
                    }
                  }}
                />
              ))}
            </Col>

            <Button
              className="button confirm"
              type="primary"
              onClick={addSignatureEmail}
              disabled={signatureEmails.filter((email) => !isEmail(email)).length}>
              Add
            </Button>
          </Menu.Item>
        </Menu>
        <h3 className="settings-label">REDIRECT SETTINGS</h3>
        <Menu className="settings-menu">
          <Menu.Item key="redirect-to" className="form-item">
            <label className="form-label">
              <span>Redirect Signed Prospect To</span>
              <Tooltip
                title="Redirect your prospect to another URL after signing. This setting will override global Redirect option in the Settings page."
                color="white">
                <InfoIcon />
              </Tooltip>
            </label>

            <Input
              value={redirectTo}
              onChange={(e) => onChangeRedirectTo(e.target.value)}
              placeholder="E.g. https://www.example.com"
              className={`redirect-to-${redirectToStatus}`}
            />
          </Menu.Item>

          <Menu.Item key="redirect-after" className="form-item">
            <label className="form-label">
              <span>After (Seconds)</span>
            </label>

            <Input
              value={redirectAfter?.toString()}
              disabled={!!!redirectTo}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              type="number"
              onChange={(e) => {
                if (e.target.value === '' || Number(e.target.value) <= 10000) {
                  setRedirectAfter(
                    e.target.value == '' && !redirectTo ? '' : Number(e.target.value)
                  );
                }
              }}
            />
          </Menu.Item>
        </Menu>
        <h3 className="settings-label">AI WRITING ASSISTANT</h3>
        <Menu className="settings-menu last-child">
          <Menu.Item key="redirect-after" className="form-item">
            <label className="form-label">
              <span>
                AI Assistant{' '}
                <Tooltip
                  overlayClassName="ai-assistant-tooltip"
                  title={
                    <>
                      Enable AI assistant in your proposals. <br />
                      Only supports English language at the moment.
                    </>
                  }
                  color="black"
                  key="white">
                  <InfoIcon />
                </Tooltip>
                <span className="beta-tag margin-start">BETA</span>
              </span>
            </label>
            <div
              className={`ai-tool-toggle ${proposalLanguage === 'english' ? '' : 'blur-overlay'}`}>
              <Switch
                id="enableAi"
                checked={enableAITool}
                disabled={proposalLanguage !== 'english'}
                onChange={(value) => {
                  setEnableAITool(!!value);
                }}
              />
              <label
                htmlFor="enableAi"
                className={`check-label ${enableAITool ? 'active' : ''}`}></label>
            </div>
          </Menu.Item>
        </Menu>
      </div>
      <Menu className="settings-menu buttons">
        <Menu.Item key="buttons" className="buttons">
          <Button
            className="button reset"
            type="primary"
            icon={<ResetIcon />}
            onClick={resetSettings}>
            Reset
          </Button>

          <Button
            className="button cancel"
            type="primary"
            onClick={() => setOptionVisibility(false)}>
            Cancel
          </Button>

          <Button className="button confirm" type="primary" onClick={saveSettings}>
            Save
          </Button>
        </Menu.Item>
      </Menu>
    </>
  );

  return (
    <Dropdown
      overlay={options}
      placement="bottomRight"
      overlayClassName="settings-menu-wrapper"
      visible={optionsVisible}
      onVisibleChange={() => setOptionVisibility(!optionsVisible)}
      trigger="click">
      <Button
        className="settings-button right"
        icon={<SettingsSmallIcon />}
        onClick={onClickMenu}
        aria-hidden="true">
        Settings
      </Button>
    </Dropdown>
  );
};

SettingsModal.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  proposal: PropTypes.instanceOf(Object).isRequired,
  saveProposal: PropTypes.func.isRequired,
  scrollToSection: PropTypes.func,
  updateLoaderState: PropTypes.func,
  propRef: PropTypes.instanceOf(Object),
  setProp: PropTypes.func.isRequired,
  configText: PropTypes.func.isRequired,
};

export default SettingsModal;