import { Button, Divider, Form, InputNumber, notification, Row, Spin, Switch } from 'antd';
import './PublishedProposalModal.scss';
import {
  AddUserIcon,
  ComingUpIcon,
  EditIcon2,
  EmailOutlineIcon,
  EyeIcon3,
  SendIcon2,
} from 'components/Icons';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { GET_EMAIL_SETTINGS } from 'graphql/queries/emailSettingsQueries';
import { useLazyQuery } from '@apollo/client';
import { EmailPreviewModal } from 'pages/Emails/Components/Preview';
import { CustomizeEmailSettingModal } from './CustomizeEmailSettingModal';
import { AddRecipientPopover, EditRecipientPopover, PopoverComponent } from './RecipientModals';

const schema = yup.object().shape({
  reminderToProspect: yup.object().shape({
    status: yup.boolean().required(),
    reminder: yup
      .number()
      .transform((value, originalValue) => (originalValue === '' ? 0 : value))
      .when('status', {
        is: true,
        then: (schema) =>
          schema
            .required('The reminder days is required.')
            .min(1, 'Reminder must be at least 1 day.')
            .max(99, 'The reminder must be set within a maximum of 99 days.'),
        otherwise: (schema) => schema.nullable(),
      }),
  }),
  secondReminderToProspect: yup
    .object()
    .shape({
      status: yup.boolean(),
      reminder: yup
        .number()
        .transform((value, originalValue) => (originalValue === '' ? 0 : value))
        .when('status', {
          is: true,
          then: (schema) =>
            schema
              .required('The reminder days is required.')
              .min(1, 'Reminder must be at least 1 day.')
              .max(99, 'The reminder must be set within a maximum of 99 days.'),
          otherwise: (schema) => schema.nullable(),
        }),
    })
    .test('reminderToProspect.status', 'Please turn on the 1st reminder.', function (field) {
      return !(this.parent.reminderToProspect.status === false && field.status === true);
    })
    .test(
      'reminderToProspect.reminder',
      '2nd reminder must be greater than 1st reminder.',
      function (field) {
        return !(
          this.parent.reminderToProspect.reminder >= field.reminder && field.status === true
        );
      }
    ),
  signedPDFToProspect: yup.object().shape({
    reminder: yup.number().optional(),
    status: yup.boolean(),
  }),
});

export const EmailSettingsSection = ({ saveProposal, onClose, proposal }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [form] = Form.useForm();
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openCustomizeSettingModal, setCustomizeSettingModal] = useState(false);
  const [templates, setTemplates] = useState({});
  const [recipients, setRecipients] = useState([]);
  const currentSetting = useRef();
  const [isSharingProposal, setIsSharingProposal] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const options = {
    reminderToProspect: {
      title: 'Send 1st reminder after',
      reminder: 5,
      status: false,
      isReminder: true,
    },
    secondReminderToProspect: {
      title: 'Send 2nd reminder after',
      reminder: 4,
      status: false,
      isReminder: true,
    },
    signedPDFToProspect: {
      title: 'Send signed copy to the prospect',
      status: true,
      hasAttachment: true,
    },
  };
  const [validationError, setValidationError] = useState({});

  const yupSync = {
    async validator({ field }, value) {
      let obj = form.getFieldsValue();
      const keys = field.split('.');

      if (keys.length > 1) {
        obj[keys[0]][keys[1]] = value;
      } else {
        obj[keys[0]] = value;
      }

      try {
        await schema.validateSyncAt(field.split('.')?.[0], obj);
        setValidationError((prev) => ({ ...prev, [keys[0]]: undefined }));
      } catch (error) {
        setValidationError((prev) => ({ ...prev, [keys[0]]: error?.errors?.[0] }));
        throw error;
      }
    },
  };

  const [fetchEmailSettings, { data: { fetchEmailSettings: emailSettings } = {}, loading }] =
    useLazyQuery(GET_EMAIL_SETTINGS, {
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    if (isOpened && !emailSettings) {
      fetchEmailSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  useEffect(() => {
    if (emailSettings) {
      const { reminderToProspect, secondReminderToProspect } = {
        ...emailSettings,
      };
      form.setFieldsValue({
        reminderToProspect: {
          status: options?.reminderToProspect?.status,
          reminder: reminderToProspect?.reminder,
        },
        secondReminderToProspect: {
          status: options?.secondReminderToProspect?.status,
          reminder: secondReminderToProspect?.reminder,
        },
        signedPDFToProspect: {
          status: options.signedPDFToProspect.status,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailSettings]);

  const onViewClick = (settingKey) => {
    currentSetting.current = {
      emailKey: settingKey,
      data: emailSettings[settingKey],
    };
    setOpenPreviewModal(true);
  };

  const onCustomizeEmail = () => {
    setOpenPreviewModal(false);
    setCustomizeSettingModal(true);
  };

  const onSubmit = async (values) => {
    if (recipients.length === 0) {
      setValidationError({ ...validationError, recipients: 'Please add at least one recipient.' });
      return;
    }
    setIsSharingProposal(true);

    const removeExtraFields = (obj) =>
      Object.entries(obj).reduce((a, [k, v]) => {
        if (!['open', 'sent', 'clicked'].includes(k)) {
          a[k] = v;
        }
        return a;
      }, {});

    const settings = Object.keys(values).reduce((a, k) => {
      if (values[k].status) {
        a[k] = {
          ...values[k],
          ...(templates?.[k]
            ? { customize: removeExtraFields({ ...templates[k], reminder: values[k].reminder }) }
            : {}),
        };
      }
      return a;
    }, {});
    saveProposal({ emailSettings: { ...settings, recipients } })
      .then(() => {
        setIsSharingProposal(false);
        notification.success({
          message: 'Success! The proposal has been emailed.',
          style: {
            width: '460px',
          },
        });
        onClose();
      })
      .catch((err) => {
        setIsSharingProposal(false);
        notification.error({
          message: 'Something went wrong. Please try again.',
        });
      });
  };

  const onAddRecipient = (recipient) => {
    setRecipients((prev) => [...prev, recipient]);
  };

  const checkFormValidity = () => {
    const errors = form.getFieldsError();
    const isValid = errors.every((field) => !field.errors.length);
    console.log(errors, 'errors'); // Check if all fields are valid
    setIsFormValid(isValid);
  };

  return (
    <Row className="email-settings-wrapper">
      <Row
        className="header-row"
        style={isOpened ? { borderBottom: '1px solid #e2e3e8' } : {}}
        onClick={() => setIsOpened(!isOpened)}>
        <Row className="header-text" justify="center">
          <EmailOutlineIcon />
          <span>Send email & auto-reminders</span>
        </Row>
        <div className="action-icon">{isOpened ? <UpOutlined /> : <DownOutlined />}</div>
      </Row>
      {isOpened ? (
        <Row style={{ width: '100%' }}>
          {loading ? (
            <Row style={{ height: '200px', width: '100%' }} justify="center" align="middle">
              <Spin />
            </Row>
          ) : (
            <Form
              form={form}
              initialValues={options}
              onFinish={onSubmit}
              onFieldsChange={checkFormValidity}
              className="proposal-email-settings-form">
              {Object.keys(options).map((settingKey) => {
                return (
                  <Row style={{ gap: '4px' }}>
                    <Row
                      key={settingKey}
                      style={{ gap: '8px', width: '100%' }}
                      wrap={false}
                      align="middle">
                      <Form.Item
                        name={[settingKey, 'status']}
                        valuePropName="checked"
                        noStyle
                        rules={[yupSync]}
                        dependencies={[
                          [
                            settingKey === 'reminderToProspect'
                              ? 'secondReminderToProspect'
                              : 'reminderToProspect',
                            'status',
                          ],
                          ['reminderToProspect', 'secondReminderToProspect'],
                          ['reminderToProspect', 'reminder'],
                        ]}>
                        <Switch size="small" />
                      </Form.Item>
                      <Form.Item
                        name={[settingKey, 'reminder']}
                        noStyle
                        dependencies={[
                          ['reminderToProspect', 'status'],
                          ['secondReminderToProspect', 'status'],
                          [
                            settingKey === 'reminderToProspect'
                              ? 'secondReminderToProspect'
                              : 'reminderToProspect',
                            'reminder',
                          ],
                        ]}
                        rules={options[settingKey]?.isReminder ? [yupSync] : null}>
                        <CustomNumberField optionInfo={options[settingKey]} />
                      </Form.Item>
                      <div className="btn-view" onClick={() => onViewClick(settingKey)}>
                        <EyeIcon3 />
                      </div>
                    </Row>
                    <Form.Item shouldUpdate={true} noStyle>
                      {({ getFieldError, getFieldValue }) => {
                        const reminderErrors = getFieldError([settingKey, 'reminder']);
                        return reminderErrors.length > 0 &&
                          getFieldValue([settingKey, 'reminder']) ? (
                          <div
                            role="alert"
                            style={{ color: '#ff4d4f' }}
                            className="ant-form-item-explain-error">
                            <div>{reminderErrors[0]}</div>
                          </div>
                        ) : null;
                      }}
                    </Form.Item>
                  </Row>
                );
              })}
              <Divider style={{ margin: '0' }} />
              <Row
                className="receipt-Recipients"
                style={{ gap: '16px' }}
                wrap={false}
                justify="space-between"
                align="top">
                <Row flex={1} className="email-list" justify="start">
                  <span>
                    <b>Recipient:</b>{' '}
                    {recipients.length > 0 ? (
                      recipients.map((recipient) => recipient.email).join('; ')
                    ) : validationError?.recipients ? (
                      <span style={{ color: ' #ff4d4f' }}>{validationError?.recipients}</span>
                    ) : (
                      'No recipient'
                    )}
                  </span>
                </Row>
                <Row wrap={false} align="top" style={{ gap: '8px' }}>
                  {recipients.length > 0 && (
                    <div className="btn-view">
                      <PopoverComponent
                        icon={<EditIcon2 />}
                        content={
                          <EditRecipientPopover
                            recipients={recipients}
                            onEditRecipient={setRecipients}
                          />
                        }
                      />
                    </div>
                  )}
                  <div className="btn-view">
                    <PopoverComponent
                      icon={<AddUserIcon />}
                      content={
                        <AddRecipientPopover
                          recipients={recipients}
                          onAddRecipient={onAddRecipient}
                        />
                      }
                    />
                  </div>
                </Row>
              </Row>
              <Row className="footer-section">
                <Button
                  htmlType="submit"
                  loading={isSharingProposal}
                  disabled={!isFormValid || recipients.length === 0}
                  className="send-proposal-btn"
                  icon={<SendIcon2 />}>
                  Send Proposal
                </Button>
                <Button disabled className="schedule-btn">
                  Schedule
                </Button>
                <div className="coming-soon-section">
                  <span>Coming soon!</span>
                  <ComingUpIcon />
                </div>
              </Row>
            </Form>
          )}
        </Row>
      ) : (
        <></>
      )}
      <EmailPreviewModal
        isOpen={openPreviewModal}
        emailKey={currentSetting.current?.emailKey}
        formData={
          templates?.[currentSetting.current?.emailKey] ||
          emailSettings?.[currentSetting.current?.emailKey]
        }
        generalSettings={emailSettings?.generalSettings}
        onCancel={() => setOpenPreviewModal(false)}
        hasAttachment={options[currentSetting.current?.emailKey]?.hasAttachment}
        staticHeader={true}
        onCustomizeEmail={onCustomizeEmail}
      />
      <CustomizeEmailSettingModal
        isOpen={openCustomizeSettingModal}
        emailKey={currentSetting.current?.emailKey}
        formData={
          templates?.[currentSetting.current?.emailKey] ||
          emailSettings?.[currentSetting.current?.emailKey]
        }
        onCancel={() => setCustomizeSettingModal(false)}
        onCustomize={(emailKey, values) => {
          setTemplates((prev) => ({ ...prev, [emailKey]: values }));
          setCustomizeSettingModal(false);
        }}
        config={options[currentSetting.current?.emailKey]}
        generalSettings={emailSettings?.generalSettings}
      />
    </Row>
  );
};

const CustomNumberField = ({ optionInfo, ...props }) => {
  return (
    <Row className="reminder-section">
      <Row style={{ gap: '8px' }} align="middle">
        <span className="text">{optionInfo.title}</span>
        {optionInfo.isReminder && (
          <InputNumber
            className="custom-number-input"
            {...props}
            type="number"
            inputMode="numeric"
            min={1}
            precision={0}
          />
        )}
        {optionInfo.isReminder && <span className="text">days</span>}
      </Row>
    </Row>
  );
};
