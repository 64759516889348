import { gql } from '@apollo/client';

export const USER_LOGIN = gql`
  query login($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      token
      user {
        _id
        emails {
          address
        }
        profile {
          name
          companyname
          address
          country
          countryCode
          vatnumber
          contactnumber
          whatsappNumber
          image
          terms
          priceSeperator
          decimalPrecision
          currency
          redirectAfter
          redirectTo
          contactButton
          callButton
          dateFormat
          role
          firstPayment
          affiliate
          infoUpdate
          oldWixUser
          seenTour
        }
        props
        roles
        otherptype
        config
        ptype
        teamId
        freshbooks
        stripe {
          stripe_user_id
          currency
          country
          isActive
        }
        quickbooks {
          expires_in
          x_refresh_token_expires_in
        }
        xero
        monday
        slack
        signature
        userway
        scripts
        chatwayScripts
        templateFilter
        domain
        cname
        planType
        dns
        defaultLogo
        affiliateId
        paymentStatus {
          canLock
          everPayed
          activeProfileStartDate
        }
        createdAt
        stopperAnchor
        isAdmin
        deletedTemplates
        reviewWidget
        subscriptionId
        zaps {
          proposalId
        }
        integromatHooks {
          enabled
        }
        wix {
          instanceId
          name
          domain
          logo
          metaSiteId
        }
        morninginvoice {
          id
          expiresIn
        }
        userHash
      }
    }
  }
`;

export const USER_GOOGLE_LOGIN = gql`
  query loginGoogle($token: String!, $affiliate: String, $landingUrl: String) {
    loginGoogleUser(token: $token, affiliate: $affiliate, landingUrl: $landingUrl) {
      token
      user {
        _id
        emails {
          address
        }
        profile {
          name
          companyname
          address
          country
          countryCode
          vatnumber
          contactnumber
          whatsappNumber
          where
          image
          terms
          priceSeperator
          decimalPrecision
          currency
          redirectAfter
          redirectTo
          contactButton
          callButton
          dateFormat
          role
          firstPayment
          affiliate
          infoUpdate
          landingUrl
          oldWixUser
          seenTour
        }
        roles
        otherptype
        config
        ptype
        teamId
        freshbooks
        monday
        xero
        slack
        stripe {
          stripe_user_id
          currency
          country
          isActive
        }
        quickbooks {
          expires_in
          x_refresh_token_expires_in
        }
        signature
        userway
        scripts
        chatwayScripts
        templateFilter
        domain
        cname
        planType
        dns
        defaultLogo
        affiliateId
        paymentStatus {
          canLock
          everPayed
        }
        createdAt
        stopperAnchor
        isAdmin
        deletedTemplates
        reviewWidget
        subscriptionId
        zaps {
          proposalId
        }
        integromatHooks {
          enabled
        }
        wix {
          instanceId
          name
          domain
          logo
          metaSiteId
        }
        wixApp {
          instanceId
          name
          domain
          logo
          metaSiteId
          locale {
            country
            languageCode
          }
        }
        morninginvoice {
          id
          expiresIn
        }
        userHash
      }
    }
  }
`;

export const USER_WIX_LOGIN = gql`
  query loginWix($instanceId: String!) {
    loginWixUser(instanceId: $instanceId) {
      token
      user {
        _id
        emails {
          address
        }
        profile {
          name
          companyname
          address
          country
          countryCode
          vatnumber
          contactnumber
          whatsappNumber
          where
          image
          terms
          priceSeperator
          decimalPrecision
          currency
          redirectAfter
          redirectTo
          contactButton
          callButton
          dateFormat
          role
          firstPayment
          affiliate
          infoUpdate
          oldWixUser
        }
        roles
        otherptype
        config
        ptype
        teamId
        freshbooks
        xero
        monday
        slack
        stripe {
          stripe_user_id
          currency
          country
          isActive
        }
        quickbooks {
          expires_in
          x_refresh_token_expires_in
        }
        signature
        userway
        scripts
        chatwayScripts
        templateFilter
        domain
        cname
        planType
        dns
        defaultLogo
        affiliateId
        paymentStatus {
          canLock
          everPayed
        }
        createdAt
        stopperAnchor
        isAdmin
        deletedTemplates
        reviewWidget
        subscriptionId
        zaps {
          proposalId
        }
        integromatHooks {
          enabled
        }
        wix {
          instanceId
          name
          domain
          logo
          metaSiteId
        }
        morninginvoice {
          id
          expiresIn
        }
        userHash
      }
    }
  }
`;

export const USER_DETAILS = gql`
  query user($id: String, $type: String) {
    fetchUser(_id: $id, type: $type) {
      _id
      emails {
        address
      }
      profile {
        name
        companyname
        address
        country
        countryCode
        vatnumber
        contactnumber
        whatsappNumber
        image
        terms
        priceSeperator
        decimalPrecision
        currency
        redirectAfter
        redirectTo
        contactButton
        callButton
        dateFormat
        role
        firstPayment
        affiliate
        infoUpdate
        allowPreviewDownload
        enableAITool
        oldWixUser
        showPdfFooter
        pdfFooterVariables
        defaultProposalFont
        seenTour
      }
      props
      roles
      otherptype
      config
      ptype
      teamId
      freshbooks
      xero
      monday
      slack
      stripe {
        stripe_user_id
        currency
        country
        isActive
      }
      quickbooks {
        expires_in
        x_refresh_token_expires_in
      }
      signature
      sigOptions
      userway
      scripts
      chatwayScripts
      templateFilter
      domain
      planType
      cname
      dns
      defaultLogo
      affiliateId
      paymentStatus {
        activeProfileId
        activeProfileStartDate
        canLock
        everPayed
        didCancel
      }
      uploadedFonts {
        family
        weights
        source
        title
        format
        url
      }
      createdAt
      stopperAnchor
      isAdmin
      deletedTemplates
      reviewWidget
      subscriptionId
      zaps {
        proposalId
      }
      integromatHooks {
        enabled
      }
      wix {
        instanceId
        name
        domain
        logo
        metaSiteId
      }
      wixApp {
        instanceId
        name
        domain
        logo
        metaSiteId
        locale {
          country
          languageCode
        }
      }
      morninginvoice {
        id
        expiresIn
      }
      expiry
      userHash
    }
  }
`;

export const USER_SETTINGS = gql`
  query user($id: String!) {
    fetchUser(_id: $id) {
      _id
      profile {
        language
        currency
        priceSeperator
        decimalPrecision
        redirectTo
        redirectAfter
        dateFormat
        contactButton
        callButton
        terms
        allowPreviewDownload
        showPdfFooter
        pdfFooterVariables
        enableAITool
        defaultProposalFont
      }
      domain
      cname
      dns
      ptype
      otherptype
      planType
      sigtype
      sigOptions
      contactOptions {
        option
        value
        enabled
      }
      signature
      roles
      signatureEmails
      expiry
      uploadedFonts {
        family
        weights
        source
        title
        format
        url
      }
    }
  }
`;

export const USER_TEAMS_DETAILS = gql`
  query usersQuery($teamId: String!) {
    fetchUsers(teamId: $teamId) {
      _id
      emails {
        address
      }
      profile {
        name
        image
      }
      props
      createdAt
      teamId
      domain
      roles
    }
  }
`;

export const USER_AFFILIATES_DETAILS = gql`
  query user($id: String!) {
    fetchUser(_id: $id) {
      _id
      emails {
        address
      }
      profile {
        name
        companyname
        address
        vatnumber
        contactnumber
        whatsappNumber
        image
        terms
        priceSeperator
        decimalPrecision
        currency
        redirectAfter
        redirectTo
        contactButton
        callButton
        dateFormat
        role
        firstPayment
        affiliate
        oldWixUser
      }
      otherptype
      config
      ptype
      teamId
      freshbooks
      xero
      monday
      slack
      planType
      stripe {
        stripe_user_id
        currency
        country
        isActive
      }
      quickbooks {
        expires_in
        x_refresh_token_expires_in
      }
      signature
      userway
      scripts
      chatwayScripts
      templateFilter
      domain
      cname
      dns
      defaultLogo
      paypalId
      credits
      affiliates {
        affiliateBy
        affiliatedUser
        status
        paid
        amount
      }
      affiliatePayments {
        requestedCredits
        paided
        createdAt
        payedAt
      }
      affiliateTransactions {
        amount
        createdAt
        sender
        receiver
      }
      affiliateId
    }
  }
`;

export const USER_PAYMENT_DETAILS = gql`
  query user($id: String!) {
    fetchUser(_id: $id) {
      _id
      emails {
        address
      }
      paymentStatus {
        activeProfileId
        activeProfileStartDate
        isLegacy
        canLock
        everPayed
        trialTime
        lastStatus
        subType
        fullSubType
        didCancel
        nextPaymentDate
        validTill
        payments
        paymentMode {
          type
          cardType
          cardNumber
          accountEmail
        }
      }
      subscriptionId
      paypalSubscriptionId
      createdAt
      planType
      teamId
      xero
      monday
      slack
    }
  }
`;
