import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Checkbox,
  Modal,
  message,
  Image,
  Tooltip,
  Tag,
  Divider,
  Space,
  Switch,
} from 'antd';
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  UploadOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import opentype from 'opentype.js';

import PageHeader from 'components/PageHeader';
import { PREFIX_NOT_ALLOWED, FOOTER_VARIABLES } from 'constants/index';
import { fontListEnglish, fontListHebrew, numberWords } from 'pages/Proposal/constants/constants';
import Languages from 'constants/languages';
import DateFormats from 'constants/date-format';
import projectsType from 'constants/project-type';
import PriceSeparator from 'constants/price-seperator';
import Visibility from 'constants/visibility';
import { SignatureTypes, SignatureOptions } from 'constants/signature';
import CloseIcon from 'components/Icons/CloseIcon';
import utils from 'utils/utils';
import Uploader from 'components/Uploader';
import Loader from 'components/Loader';
import MultiSelect from 'components/MultiSelect';
import InfoIcon from 'components/Icons/InfoIcon';
import UploadFontIcon from 'components/Icons/UploadFontIcon';
import SettingsResetIcon from 'components/Icons/SettingsResetIcon';
import SelectBinIcon from 'components/Icons/SelectBinIcon';
import UploadInfoIcon from 'components/Icons/UploadInfoIcon';
import ContentLibraryIcon from 'components/Icons/ContentLibraryIcon';

import { USER_SETTINGS } from 'graphql/queries/userQueries';
import { USER_SETTINGS_UPDATE } from 'graphql/mutations/userMutations';
import { getUserId, userVar } from 'graphql/cache';
import { resizeImageForS3 } from 'utils/uploadToS3';
import { sanitizeObject } from 'utils/xss';
import { addFontFaceRule, preloadFont } from 'helpers/proposal';
import useSuspiciousLink from 'hooks/useSuspiciousLink';

import './Settings.scss';

const { Option, OptGroup } = Select;
const maxFontSizeMB = 1;
const maxFontSizeBytes = maxFontSizeMB * 1024 * 1024;

const initialSettings = (user) => {
  return {
    language: '',
    currency: '',
    priceSeperator: PriceSeparator[3].value,
    decimalPrecision: 2,
    redirectTo: '',
    dateFormat: 'MM/DD/YYYY',
    redirectAfter: 0,
    contactButton: '',
    callButton: '',
    domain: '',
    cname: '',
    allowPreviewDownload: true,
    dns: '',
    ptype: '',
    otherptype: '',
    sigtype: '',
    sigOptions: ['type', 'upload', 'draw'],
    contactOptions: [
      {
        option: 'email',
        value: user?.emails[0]?.address || '',
        enabled: true,
      },
      {
        option: 'call',
        value: user?.profile?.contactnumber || '',
        enabled: !!user?.profile?.contactnumber,
      },
      {
        option: 'whatsapp',
        value: user?.profile?.whatsappNumber || '',
        enabled: !!user?.profile?.whatsappNumber,
      },
    ],
    signature: '',
    signatureEmails: [],
    expiry: '',
    showPdfFooter: user?.profile?.showPdfFooter === null ? true : user?.profile?.showPdfFooter,
    footerVariable: null,
    pdfFooterVariables:
      user?.profile?.pdfFooterVariables === null
        ? '{{proposal.title}}'
        : user?.profile?.pdfFooterVariables,
    enableAITool: user?.profile?.enableAITool === null ? false : user?.profile?.enableAITool,
    defaultProposalFont: user?.profile?.defaultProposalFont || 'Poppins',
    uploadedFonts: user?.uploadedFonts || [],
  };
};

const Settings = () => {
  const ref = useRef();
  const inputRef = useRef();
  const inputFileRef = useRef(null);
  const user = useReactiveVar(userVar);
  const { checkSuspiciousUrl, addSpamlinks } = useSuspiciousLink({
    userId: getUserId(),
  });
  const [openFontSelect, setOpenFontSelect] = useState(false);
  const [fontList, setFontList] = useState([]);
  const [customFontList, setCustomFontList] = useState(initialSettings(user).uploadedFonts);
  const [uploadingFont, setUploadingFont] = useState(false);
  const [removingFont, setRemovingFont] = useState('');

  const [footerVariables, setFooterVariables] = useState(FOOTER_VARIABLES);
  const [, setActiveVariables] = useState('');
  const [settings, setSettings] = useState(initialSettings(user));
  const [uploadingImage, setUploadingImage] = useState(false);
  const [clickedCheckbox, setClickedCheckbox] = useState(projectsType.map(() => false));
  const [domainChanged, setDomainChanged] = useState(false);
  const [signatureType, setSignatureType] = useState();
  const [signatureOptions, setSignatureOptions] = useState(['type', 'upload', 'draw']);
  const [saving, setSaving] = useState(false);
  const [signatureEmail, setSignatureEmail] = useState('');
  const [expiryOptions, setExpiryOptions] = useState(['0', '7', '15', '30']);
  const [customExpiry, setCustomExpiry] = useState('');
  const [messageShowing, setMessageShowing] = useState(false);
  const [canUseAI, setCanUseAI] = useState(false);
  const [changedKeys, setChangedKeys] = useState([]);
  const toastTimer = useRef(null);

  const handlePrelistLoad = (langChange) => {
    let prelist = [];

    if (settings.language?.toLowerCase() === 'hebrew' || langChange === 'hebrew') {
      prelist = fontListHebrew;
    } else {
      prelist = fontListEnglish;
    }

    setFontList(prelist);
  };

  useEffect(() => {
    if (settings.language) {
      handlePrelistLoad(settings.language?.toLowerCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const getExpiryList = useCallback(() => {
    const data = [...expiryOptions].map((e) => parseInt(e));
    data.sort((a, b) => a - b);

    return data.map((item) => ({
      label: item === 0 ? 'None' : `${item} Days`,
      value: item.toString(),
    }));
  }, [expiryOptions]);

  const addCustomExpiry = () => {
    let value = customExpiry.replace(/[^0-9]/g, '');

    if (value.length < 1) {
      if (inputRef?.current) {
        inputRef.current.focus();
      }
      return;
    } else if (parseInt(value, 10) <= 0) {
      return message.error('0 days is not allowed');
    } else if (parseInt(value, 10) > 99999) {
      return message.error('Max Days allowed is 99999');
    }

    // dont duplicate id already exists
    if (!expiryOptions.includes(value)) {
      setExpiryOptions((prev) => [...prev, value]);
    }
    // set the value in form
    handleFieldsChange('expiry', value);
    setCustomExpiry('');
  };

  const [upload] = Uploader();

  const setUserSettingsInfo = (userInfo) => {
    const {
      fetchUser: {
        profile: {
          language,
          currency,
          priceSeperator,
          decimalPrecision,
          redirectTo,
          dateFormat,
          redirectAfter,
          contactButton,
          allowPreviewDownload,
          callButton,
          showPdfFooter,
          pdfFooterVariables,
          defaultProposalFont,
          enableAITool,
        },
        domain,
        dns,
        cname,
        ptype,
        otherptype,
        sigtype,
        sigOptions,
        contactOptions,
        signature,
        signatureEmails,
        expiry,
        spamUrls,
        uploadedFonts,
      },
    } = userInfo;

    const userProfile = { ...user.profile, ...userInfo.fetchUser.profile };
    const userDetails = { ...user, ...userInfo.fetchUser };
    userDetails.profile = userProfile;
    userVar(userDetails);

    if (spamUrls?.length) {
      // spam link found, add to cache
      addSpamlinks(spamUrls);
    }

    setSettings({
      ...settings,
      language,
      currency,
      priceSeperator: priceSeperator || initialSettings().priceSeperator,
      decimalPrecision,
      dateFormat: dateFormat || initialSettings().dateFormat,
      redirectTo,
      redirectAfter,
      contactButton,
      allowPreviewDownload,
      callButton,
      domain,
      ptype,
      otherptype,
      sigtype,
      dns,
      cname,
      showPdfFooter,
      pdfFooterVariables,
      sigOptions: sigOptions?.length
        ? [...new Set(sigOptions)]
        : [...new Set(initialSettings().sigOptions)],
      contactOptions: contactOptions?.length
        ? [...new Set(contactOptions)]
        : [...new Set(initialSettings(user).contactOptions)],
      signature,
      signatureEmails: signatureEmails || [],
      expiry: expiry || '',
      enableAITool: userProfile?.language === 'english' ? enableAITool : false,
      defaultProposalFont: defaultProposalFont || initialSettings().defaultProposalFont,
      uploadedFonts: uploadedFonts?.length
        ? [...new Set(uploadedFonts)]
        : [...new Set(initialSettings(user).uploadedFonts)],
    });

    if (sigtype !== signatureType) {
      setSignatureType(settings.sigtype || sigtype);
    }

    if (
      sigOptions?.length === signatureOptions.length &&
      sigOptions?.every((value, index) => value === signatureOptions[index])
    ) {
      setSignatureOptions([...new Set(settings.sigOptions)] || [...new Set(sigOptions)]);
    }

    if (
      contactOptions?.length === signatureOptions.length &&
      contactOptions?.every((value, index) => value === signatureOptions[index])
    ) {
      setSignatureOptions([...new Set(settings.contactOptions)] || [...new Set(contactOptions)]);
    }

    const tempclickedCheckbox = projectsType.map(() => false);
    if (ptype) {
      ptype.split(' ').forEach((el) => {
        const index = projectsType.findIndex((type) => type.key === el);
        if (index !== -1) {
          tempclickedCheckbox[index] = true;
          setClickedCheckbox([...tempclickedCheckbox]);
        }
      });
    }
    if (otherptype) {
      tempclickedCheckbox[tempclickedCheckbox.length - 1] = true;
      setClickedCheckbox([...tempclickedCheckbox]);
    }

    if (expiry && !expiryOptions.includes(expiry)) {
      setExpiryOptions([...expiryOptions, expiry]);
    }
  };

  const [loadUser, { loading, data }] = useLazyQuery(USER_SETTINGS, {
    variables: { id: getUserId() },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setUserSettingsInfo(res);
    },
  });

  const [saveUserFont] = useMutation(USER_SETTINGS_UPDATE, {
    onCompleted() {
      loadUser();
      setUploadingFont(false);
      setRemovingFont('');
    },
    onError: ({ graphQLErrors }) => {
      setUploadingFont(false);
      setRemovingFont('');
      message.error(graphQLErrors[0].message);
    },
  });

  const [saveSettings] = useMutation(USER_SETTINGS_UPDATE, {
    onCompleted() {
      loadUser();
      setSaving(false);
      message.success('Settings updated successfully');
    },
    onError: ({ graphQLErrors }) => {
      setSaving(false);
      message.error(graphQLErrors[0].message);
    },
  });

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  useEffect(() => {
    document.title = 'Prospero - Settings';
  });

  useEffect(() => {
    const variables = settings.pdfFooterVariables || '';
    const selectedKeys = variables.split(' ');
    const updatedFooterVariables = footerVariables.map((variable) => ({
      ...variable,
      checked: selectedKeys.includes(variable.key),
    }));
    setFooterVariables(updatedFooterVariables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    if (user?.profile?.language === 'english') {
      setCanUseAI(true);
    }
  }, [user]);

  const handleClearCanvas = () => {
    if (settings.signature) {
      settings.signature = '';
      setSettings({ ...settings });
    } else {
      ref.current.clear();
    }
  };

  const handleFieldsChange = (name, val) => {
    const updateFooterVariables = () => {
      const updatedFooterVariables = footerVariables.map((item) =>
        item.id === val.id ? { ...item, checked: !val.checked } : item
      );

      setFooterVariables(updatedFooterVariables);

      let newFooterTitle = settings?.pdfFooterVariables || '';

      if (!val.checked) {
        if (!newFooterTitle.includes(val.key)) {
          newFooterTitle = `${newFooterTitle} ${val.key}`.trim();
        }
      } else {
        newFooterTitle = newFooterTitle
          .replace(val.key, '')
          .replace(/\s{2,}/g, ' ')
          .trim();
      }

      settings.pdfFooterVariables = newFooterTitle;
      setSettings({ ...settings });
    };

    const updateSignatureType = () => {
      setSignatureType(val);

      if (data?.fetchUser?.sigtype !== val) {
        settings.signature = '';
      } else {
        settings.signature = data?.fetchUser?.signature;
      }

      setSettings({ ...settings });
    };

    const updateSignatureOptions = () => {
      const tempSigType =
        val[0] === 'draw' ? 'sigdraw' : val[0] === 'upload' ? 'sigimage' : 'sigtext';

      if (
        (val.includes('draw') && settings.sigtype !== 'sigdraw') ||
        (val.includes('type') && settings.sigtype !== 'sigtext') ||
        (val.includes('upload') && settings.sigtype !== 'sigimage')
      ) {
        settings.sigtype = tempSigType;
        setSignatureType(tempSigType);
      }

      setSignatureOptions(val);
      setSettings({ ...settings });
    };
    
    const updateContactOptions = () => {
      const updatedContactOptions = settings.contactOptions.map((contactOption) =>
        val.includes(contactOption.option)
          ? { ...contactOption, enabled: true }
          : { ...contactOption, enabled: false }
      );

      settings.contactOptions = updatedContactOptions;
      setSettings({ ...settings });
    };

    const updateLanguageSettings = () => {
      if (val === 'english') {
        settings.enableAITool = initialSettings(user).enableAITool;
        setCanUseAI(true);
      } else {
        settings.enableAITool = false;
        setCanUseAI(false);
      }
    };

    const handleSpecificFields = () => {
      switch (name) {
        case 'footerVariable':
          updateFooterVariables();
          break;
        case 'pdfFooterVariables':
          settings.pdfFooterVariables = val;
          setSettings({ ...settings });
          break;
        case 'sigtype':
          updateSignatureType();
          break;
        case 'domain':
          setDomainChanged(true);
          break;
        case 'sigOptions':
          updateSignatureOptions();
          break;
        case 'contactOptions':
          updateContactOptions();
          break;
        case 'currency':
          val = val.substring(0, 10);
          break;
        case 'language':
          updateLanguageSettings();
          break;
        case 'redirectTo':
          setChangedKeys((prev) => [...new Set([...prev, 'redirectTo'])]);
          break;
        case 'defaultProposalFont':
          settings.defaultProposalFont = val;
          break;
        default:
          break;
      }
    };
    
    handleSpecificFields();
    settings[name] = val;
    setSettings({ ...settings });
  };

  const handleContactOptionValue = (name, val) => {
    settings['contactOptions'] = settings?.contactOptions?.map((contactOption) => {
      if (name === contactOption.option) {
        return { ...contactOption, value: val };
      } else {
        return { ...contactOption };
      }
    });
    return setSettings({ ...settings });
  };

  const handleContactCheckbox = (name, val) => {
    settings['contactOptions'] = settings?.contactOptions?.map((contactOption) => {
      if (name === contactOption.option) {
        return { ...contactOption, enabled: !val };
      } else {
        return { ...contactOption };
      }
    });
    return setSettings({ ...settings });
  };

  const handleCheckboxClick = (index) => {
    clickedCheckbox[index] = !clickedCheckbox[index];
    setClickedCheckbox([...clickedCheckbox]);

    if (index === clickedCheckbox.length - 1) {
      settings.otherptype = '';
      setSettings({ ...settings });
    }
  };

  const handleSignatureUpload = async ({ file }) => {
    try {
      const size = { width: 300, height: 105 };

      const reSizedImage = await resizeImageForS3({
        file: file,
        path: 'signatures',
        size,
      });

      const { url } = await upload(reSizedImage, `signatures/${user._id}`);
      return url;
    } catch (error) {
      console.log('error', error);
      message.error(error.message);
    }
  };

  const handleImageUpload = async (files) => {
    if (!files.length) return;

    setUploadingImage(true);

    try {
      const url = await handleSignatureUpload({ file: files[0] });
      setUploadingImage(false);
      const settingsData = { ...settings };
      settingsData.signature = url;
      setSettings(settingsData);
    } catch (e) {
      setUploadingImage(false);
      message.error(e.message);
    }
  };

  const saveSettingsData = async (fontObject) => {
    let ptype = '';
    let settingsData = { ...settings };

    if (fontObject) {
      setOpenFontSelect(true);
      settingsData.uploadedFonts = fontObject;
    } else {
      setSaving(true);
    }
    clickedCheckbox.forEach((el, index) => {
      if (el) {
        ptype += projectsType[index].key;
        if (index !== clickedCheckbox.length - 1) {
          ptype += ' ';
        }
      }
    });

    if (settingsData.sigtype === 'sigdraw' && ref.current && !ref.current.isEmpty()) {
      const img = ref.current.toDataURL('image/png');
      const file = utils.dataURItoBlob(img);
      file['name'] = uuidv4();

      try {
        const url = await handleSignatureUpload({ file });

        settingsData.signature = url;

        setSettings(settingsData);
      } catch (e) {
        message.error(e.message);
      }
    }

    if (settingsData.sigtype === 'sigtext' && utils.checkValidURL(settingsData.signature)) {
      settingsData.signature = user?.profile?.name;
    }

    if (!settingsData.signature) {
      setSaving(false);
      return message.error('Signature Required!');
    }
    let error = false;
    /* eslint-disable */
    if (settingsData.domain === '') {
      error = true;
      message.error('Proposal link prefix cannot be empty');
    } else if (settingsData.domain.indexOf(' ') > -1) {
      error = true;
      message.error('Proposal link prefix cannot have spaces');
    } else if (PREFIX_NOT_ALLOWED.includes(settingsData.domain)) {
      error = true;
      message.error(`Proposal link prefix '${settingsData.domain}' not allowed`);
    } else if (settingsData.domain.length > 50) {
      error = true;
      message.error('Proposal link prefix cannot be greater than 50 characters');
    } else if (PREFIX_NOT_ALLOWED.includes(settingsData.domain)) {
      error = true;
      message.error(`Proposal link prefix '${settingsData.domain}' not allowed`);
    } else if (!/^[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]$/.test(settingsData.domain)) {
      error = true;
      message.error('Invalid Proposal link prefix');
    } else if (
      !!settingsData.contactOptions[1].value &&
      !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(settingsData.contactOptions[1].value)
    ) {
      error = true;
      message.error('Invalid Phone number');
    } else if (
      !!settingsData.contactOptions[2].value &&
      !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(settingsData.contactOptions[2].value)
    ) {
      error = true;
      message.error('Invalid whatsapp number');
    } else if (
      !!settingsData.contactOptions[0].value &&
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        settingsData.contactOptions[0].value
      )
    ) {
      error = true;
      message.error('Invalid email');
    } else if (
      settingsData.redirectTo &&
      !/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,50}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
        settingsData.redirectTo
      )
    ) {
      error = true;
      message.error('Invalid redirect signed client to url');
    }
    /* eslint-enable */

    if (!error) {
      settingsData = {
        ...settingsData,
        ...sanitizeObject({
          currency: settingsData.currency,
          domain: settingsData.domain,
          otherptype: settingsData.otherptype,
        }),
      };

      if (changedKeys.includes('redirectTo')) {
        // if user changed redirectTo, check for spam
        checkSuspiciousUrl(settingsData.redirectTo);
      }

      const variables = {
        id: getUserId(),
        profile: {
          language: settingsData?.language?.toLowerCase(),
          currency: settingsData.currency,
          priceSeperator: settingsData.priceSeperator,
          decimalPrecision: settingsData.decimalPrecision,
          redirectTo: settingsData.redirectTo,
          allowPreviewDownload: settingsData.allowPreviewDownload,
          dateFormat: settingsData.dateFormat,
          redirectAfter: settingsData.redirectAfter,
          callButton: settingsData.callButton,
          showPdfFooter: settingsData.showPdfFooter,
          pdfFooterVariables: settingsData.pdfFooterVariables,
          enableAITool: settingsData.enableAITool,
          defaultProposalFont: settingsData.defaultProposalFont,
        },
        domain: settingsData.domain,
        cname: settingsData.cname,
        dns: settingsData.dns,
        ptype,
        otherptype: settingsData.otherptype,
        sigtype: settingsData.sigtype,
        sigOptions: settingsData.sigOptions,
        contactOptions: settingsData.contactOptions,
        signature: settingsData.signature,
        signatureEmails: settingsData.signatureEmails || [],
        expiry: settingsData.expiry.toString() || '',
        uploadedFonts: settingsData.uploadedFonts,
      };
      if (fontObject) {
        return saveUserFont({
          variables,
        });
      }
      saveSettings({
        variables,
      });

      setChangedKeys([]);
    } else {
      setSaving(false);
    }
  };

  const handleSave = () => {
    if (domainChanged) {
      Modal.confirm({
        title: 'Are you sure?',
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content:
          'Changing your proposal link prefix will break links that were sent using an old prefix',
        okText: 'SAVE',
        cancelText: 'CANCEL',
        onOk: () => {
          saveSettingsData();
          setDomainChanged(false);
        },
      });
    } else {
      saveSettingsData();
    }
  };

  const handleReset = () => {
    setUserSettingsInfo(data);
  };

  const handleSignatureEmail = () => {
    /* eslint-disable */
    if (
      !!signatureEmail &&
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        signatureEmail
      )
    ) {
      message.error('Invalid signature emails');
    } else {
      const tempSignatureEmails = [...settings.signatureEmails] || [];
      if (signatureEmail && tempSignatureEmails.indexOf(signatureEmail) === -1) {
        tempSignatureEmails.push(signatureEmail);
      }
      handleFieldsChange('signatureEmails', tempSignatureEmails);
      setSignatureEmail('');
    }
    /* eslint-enable */
  };

  const removeSignatureEmail = (emailIndex) => {
    const tempSignatureEmails = [...settings.signatureEmails] || [];
    tempSignatureEmails.splice(emailIndex, 1);
    handleFieldsChange('signatureEmails', tempSignatureEmails);
  };

  if (loading && !signatureType) {
    return <Loader />;
  }

  const uploadUserFont = () => {
    inputFileRef.current.click();
  };
  const validateFileSize = (file) => {
    if (file.size > maxFontSizeBytes) {
      message.error(`The file size exceeds the limit of ${maxFontSizeMB}MB.`);
      return false;
    }
    return true;
  };
  const handleFontUploadError = (error) => {
    setUploadingFont(false);
    message.error(error.message);
  };
  const extractFontInfo = (font, filename, fontFormat) => {
    const fontFamily = font.names.fullName.en || filename.split('.')[0];
    const fontTitle = font.tables.name.postScriptName.en || filename.split('.')[0];
    const weight = font.tables.os2.usWeightClass?.toString() || '400';
    return {
      family: fontFamily,
      weights: [weight],
      source: 'custom',
      title: fontTitle,
      format: fontFormat,
    };
  };
  const getFontFormat = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    switch (extension) {
      case 'ttf':
        return 'truetype';
      case 'otf':
        return 'opentype';
      case 'woff':
        return 'woff';
      case 'woff2':
        return 'woff2';
      default:
        return 'unknown';
    }
  };
  const uploadFont = async (file) => {
    const { url, filename } = await upload(file, `userfonts/${user._id}`);
    const arrayBuffer = await file.arrayBuffer();
    const font = opentype.parse(arrayBuffer);

    const fontFormat = getFontFormat(filename);

    return {
      fontInfo: extractFontInfo(font, filename, fontFormat),
      url,
    };
  };

  const onUserFontUpload = async ({ target }) => {
    const {
      validity,
      files: [file],
    } = target;
    if (!validity.valid || !validateFileSize(file)) return;

    setUploadingFont(true);
    const existingFonts = settings?.uploadedFonts || [];
    try {
      const { fontInfo, url } = await uploadFont(file);
      let newFont = { ...fontInfo, url };

      let fontExists = existingFonts.some((font) => font.family === newFont.family);
      let counter = 0;

      while (fontExists && counter < numberWords.length) {
        const suffix = ` ${numberWords[counter]}`;
        newFont.title = `${fontInfo.title}${suffix}`;
        newFont.family = `${fontInfo.family}${suffix}`;
        fontExists = existingFonts.some((font) => font.family === newFont.family);
        counter++;
      }

      const updatedFonts = [...existingFonts, newFont];

      await saveSettingsData(updatedFonts);

      const { family, url: fontUrl, weights, format } = newFont;
      preloadFont(fontUrl);
      addFontFaceRule(family, fontUrl, weights, format);
      setCustomFontList(updatedFonts);
    } catch (error) {
      setCustomFontList(existingFonts);
      handleFontUploadError(error);
    } finally {
      target.value = '';
    }
  };

  const handleRemoveFont = async (e, font) => {
    e.stopPropagation();
    setRemovingFont(font.family);
    try {
      const updatedFonts = customFontList.filter((f) => f.family !== font.family);
      const currentFont = settings.defaultProposalFont;
      if (currentFont === font.family) {
        settings.defaultProposalFont = 'Poppins';
        setSettings({ ...settings });
      }

      await saveSettingsData(updatedFonts);
      setCustomFontList(updatedFonts);
    } catch (error) {
      setRemovingFont('');
      handleFontUploadError(error);
    }
  };

  const isSettingsDisabled =
    data?.fetchUser?.roles &&
    (data?.fetchUser?.roles[0] === 'editor' || data?.fetchUser?.roles[0] === 'manager')
      ? true
      : false;

  const userDefinedFooter = settings.pdfFooterVariables || '';
  const proposalTitle = 'Website design proposal.';
  const clientName = 'John Doe.';
  const clientCompany = 'Doe Inc.';
  const companyName = 'Acme Inc.';
  const currentDate = moment(new Date()).format(settings?.dateFormat || 'DD/MM/YYYY');
  const previewVariables = userDefinedFooter
    .replace(/{{proposal.title}}/g, proposalTitle)
    .replace(/{{sender.company}}/g, companyName)
    .replace(/{{client.fullName}}/g, clientName)
    .replace(/{{client.company}}/g, clientCompany)
    .replace(/{{TodayDate}}/g, currentDate);

  return (
    <div className="container narrow-container">
      <div className="settings">
        <Form layout="vertical">
          <PageHeader
            pageTitle="Settings"
            showButton={
              <>
                <Button
                  type="link"
                  className="large-btn transparent-btn reset-btn"
                  onClick={
                    !isSettingsDisabled ? handleReset : () => message.error('Permission denied')
                  }
                  disabled={saving || isSettingsDisabled}
                  icon={<SettingsResetIcon />}>
                  Reset
                </Button>

                <Button
                  className="large-btn secondary-btn"
                  type="primary"
                  onClick={
                    !isSettingsDisabled ? handleSave : () => message.error('Permission denied')
                  }
                  loading={saving}
                  disabled={isSettingsDisabled}>
                  <span className="below-md">SAVE</span>
                  <span className="above-md">SAVE CHANGES</span>
                </Button>
              </>
            }
          />

          <div className="settings-form">
            <div className="general-section">
              <div className="title-wrap">
                <h3>General</h3>
              </div>
              <Row className="content">
                <Col xs={24} md={12}>
                  <div className="input-container">
                    <div className="form-item">
                      <label className="form-label">Proposal language</label>
                      <Select
                        value={settings && settings.language ? settings.language : ''}
                        onChange={(val) => handleFieldsChange('language', val)}
                        className="select-custom-suffix"
                        disabled={isSettingsDisabled}>
                        {Languages.map((language) => (
                          <Option key={language.value} value={language.value}>
                            {language.text}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </Col>

                <Col xs={24} md={12}>
                  <div className="input-container">
                    <div className="form-item">
                      <label className="form-label">Default currency</label>
                      <Input
                        value={settings ? settings.currency : '$'}
                        onChange={(e) => handleFieldsChange('currency', e.target.value)}
                        disabled={isSettingsDisabled}
                      />
                    </div>
                  </div>
                </Col>

                <Col xs={24} md={12}>
                  <div className="input-container">
                    <div className="form-item">
                      <label className="form-label">
                        <span>Proposal Expiry Date</span>
                        <Tooltip
                          overlayClassName="wizard-tooltip-email settings-tip"
                          title="Set proposal expiry date to all proposals."
                          color="black"
                          key="white">
                          <InfoIcon className="info-icon" />
                        </Tooltip>
                      </label>
                      <Select
                        className="select-custom-suffix"
                        value={settings?.expiry || '0'}
                        placeholder="Proposal Expiry Date"
                        onChange={(val) => handleFieldsChange('expiry', val)}
                        dropdownRender={(menu) => (
                          <div className="expiry-settings">
                            {menu}
                            <Divider className="divider" />
                            <Space className="custom-box">
                              <Input
                                ref={inputRef}
                                placeholder="e.g. 10"
                                type="tel"
                                value={customExpiry}
                                onChange={(e) => {
                                  let v = parseInt(e.target.value.replace(/\D/g, ''));
                                  v = Number.isNaN(v) ? '' : v.toString();
                                  if (v.length > 5) {
                                    if (!messageShowing) {
                                      message.error('Max Days allowed is 99999');
                                    }
                                    setMessageShowing(true);

                                    if (toastTimer.current) {
                                      clearTimeout(toastTimer.current);
                                    }
                                    toastTimer.current = setTimeout(() => {
                                      setMessageShowing(false);
                                    }, 2000);
                                    v = 99999;
                                  }
                                  setCustomExpiry(v);
                                }}
                                onKeyDown={(e) => {
                                  e.stopPropagation();
                                  if (e.key === 'Enter') {
                                    addCustomExpiry();
                                  }
                                }}
                              />
                              <Button
                                type="text"
                                onClick={addCustomExpiry}
                                disabled={customExpiry.length === 0}>
                                Add custom
                              </Button>
                            </Space>
                          </div>
                        )}>
                        {getExpiryList().map((expiry) => (
                          <Select.Option key={expiry.value} value={expiry.value}>
                            {expiry.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </Col>

                <Col xs={24} md={12}>
                  <div className="input-container">
                    <div className="form-item">
                      <label className="form-label">Proposal link prefix</label>
                      <Input
                        value={settings ? settings.domain : ''}
                        onChange={(e) => handleFieldsChange('domain', e.target.value)}
                        disabled={isSettingsDisabled}
                      />
                    </div>
                  </div>
                </Col>

                <Col xs={24} md={12}>
                  <div className="input-container">
                    <div className="form-item">
                      <label className="form-label">Price separator</label>
                      <Select
                        value={settings ? settings.priceSeperator : ''}
                        onChange={(val) => handleFieldsChange('priceSeperator', val)}
                        className="select-custom-suffix"
                        disabled={isSettingsDisabled}>
                        {PriceSeparator.map((separator) => (
                          <Option key={separator.value} value={separator.value}>
                            {separator.text}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </Col>

                <Col md={12} lg={12} xl={12}>
                  <div className="input-container">
                    <div className="form-item">
                      <label className="form-label">
                        <span>Default Proposal Font</span>
                      </label>
                      <input
                        type="file"
                        className="file_bag"
                        accept=".ttf,.woff,.woff2,.otf"
                        ref={inputFileRef}
                        onChange={onUserFontUpload}
                        style={{ display: 'none', position: 'absolute', left: -10000 }}
                      />
                      <Select
                        placeholder="Poppins"
                        value={settings.defaultProposalFont}
                        allowClear
                        className="select-custom-suffix"
                        disabled={isSettingsDisabled || uploadingFont}
                        loading={uploadingFont}
                        open={openFontSelect}
                        onDropdownVisibleChange={(visible) => setOpenFontSelect(visible)}
                        onChange={(val) => handleFieldsChange('defaultProposalFont', val)}
                        dropdownRender={(menu) => (
                          <div className="font-select-with-upload">
                            {menu}
                            <div className="font-upload-option" onClick={uploadUserFont}>
                              <UploadFontIcon /> <span>Upload Font</span>
                              <Tooltip
                                overlayClassName="wizard-tooltip-email settings-tip"
                                title="Upload custom fonts in these formats: .ttf, .otf, .woff, .woff2"
                                color="black"
                                key="white">
                                <UploadInfoIcon className="info-icon upload-info-icon" />
                              </Tooltip>
                            </div>
                          </div>
                        )}>
                        {customFontList.length > 0 && (
                          <>
                            <OptGroup key="uploadedFonts" label="Uploaded Fonts">
                              {customFontList.map((font) => (
                                <Option className="custom-font-item" key={font.family}>
                                  {font.family}
                                  {removingFont === font.family ? (
                                    <LoadingOutlined className="font-loading" />
                                  ) : (
                                    <SelectBinIcon
                                      className="font-trash-icon"
                                      onClick={(e) => handleRemoveFont(e, font)}
                                    />
                                  )}
                                </Option>
                              ))}
                            </OptGroup>
                            <Option className="divider" />
                          </>
                        )}
                        {fontList.map((font) => (
                          <Option key={font.family}>{font.family}</Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </Col>

                <Col xs={24} md={12}>
                  <Col xs={24} md={24}>
                    <div className="input-container">
                      <div className="form-item margin-bottom">
                        <label className="form-label">Date format</label>
                        <Select
                          value={settings ? settings.dateFormat : ''}
                          onChange={(val) => handleFieldsChange('dateFormat', val)}
                          className="select-custom-suffix"
                          disabled={isSettingsDisabled}>
                          {DateFormats.map((format) => (
                            <Option key={format.value} value={format.value}>
                              {format.text}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={24}>
                    <div className="input-container">
                      <div className="form-item margin-bottom">
                        <label className="form-label">Contact buttons</label>
                        <div className="contact-button-bgx">
                          {settings?.contactOptions?.map((contactOption) => (
                            <Col
                              className="input-container full-width-input input-mbpl-5"
                              key={contactOption.option}>
                              <div className="form-item contact-option-input">
                                <span className="form-label form-label-closer">
                                  <span>
                                    <Checkbox
                                      disabled={isSettingsDisabled}
                                      checked={contactOption.enabled}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleContactCheckbox(
                                          contactOption.option,
                                          contactOption.enabled
                                        );
                                      }}
                                    />
                                    <span className="contact-color"> {contactOption.option} </span>
                                  </span>
                                  {contactOption.option !== 'email' && (
                                    <Tooltip
                                      overlayClassName="wizard-tooltip-email settings-tip"
                                      title={
                                        <>
                                          Make sure to include the country code in the mobile number
                                          for <br />
                                          {contactOption.option === 'call'
                                            ? 'phone calls.'
                                            : 'WhatsApp messages.'}
                                        </>
                                      }
                                      color="black"
                                      key="white">
                                      <InfoIcon className="info-icon" />
                                    </Tooltip>
                                  )}
                                </span>
                                <Input
                                  type={contactOption.option === 'email' ? 'email' : 'number'}
                                  value={contactOption.value}
                                  placeholder={
                                    contactOption.option !== 'email'
                                      ? '+12163547758'
                                      : 'example@email.com'
                                  }
                                  disabled={isSettingsDisabled || !contactOption.enabled}
                                  onChange={(e) =>
                                    handleContactOptionValue(contactOption.option, e.target.value)
                                  }
                                />
                              </div>
                            </Col>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Col>

                <Col xs={24} md={12}>
                  <Col xs={24} md={24}>
                    <div className="input-container">
                      <div className="form-item margin-bottom">
                        <label className="form-label">Decimal precision</label>
                        <Select
                          value={settings.decimalPrecision ? `${settings.decimalPrecision}` : '2'}
                          onChange={(val) =>
                            handleFieldsChange('decimalPrecision', Number.parseInt(val, 10))
                          }
                          className="select-custom-suffix"
                          disabled={isSettingsDisabled}>
                          {['1', '2', '3'].map((value) => (
                            <Option key={value} value={value}>
                              {value}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </Col>
                  <Row gutter={[10, 0]} className="margin-bottom">
                    <Col xs={16} md={16}>
                      <div className="input-container">
                        <div className="form-item">
                          <label className="form-label">Redirect Signed Prospect to</label>
                          <Input
                            placeholder="Redirect URL"
                            value={settings ? settings.redirectTo : ''}
                            onChange={(e) =>
                              handleFieldsChange('redirectTo', e.target.value.replace(/\s/g, ''))
                            }
                            disabled={isSettingsDisabled}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={8} md={8}>
                      <div className="input-container">
                        <div className="form-item">
                          <label className="form-label transform-none">
                            <span>
                              After
                              <span className="below-lg">(sec)</span>
                              <span className="above-lg">(seconds)</span>
                            </span>
                          </label>
                          <Input
                            value={settings?.redirectAfter || 0}
                            type="number"
                            onChange={(e) =>
                              handleFieldsChange('redirectAfter', Number(e.target.value))
                            }
                            disabled={isSettingsDisabled || !!!settings.redirectTo}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Col xs={24} md={24}>
                    <div className="input-container">
                      <div className="form-item">
                        <label className="form-label">
                          <span>Send Signed Proposal To</span>
                          <Tooltip
                            overlayClassName="wizard-tooltip-email settings-tip"
                            title="An email with the signed proposal (PDF) will be sent to your teammates' email addresses you added."
                            color="black"
                            key="white">
                            <InfoIcon className="info-icon" />
                          </Tooltip>
                        </label>
                        <Input
                          value={signatureEmail}
                          onChange={(e) => setSignatureEmail(e.target.value)}
                          disabled={isSettingsDisabled}
                          placeholder="example@email.com"
                          suffix={
                            <Button
                              className="signed-email-add-btn"
                              onClick={handleSignatureEmail}
                              type="ghost">
                              Add
                            </Button>
                          }
                        />
                        <Row className="signed-email-content">
                          {settings?.signatureEmails?.map((email, emailIndex) => (
                            <Tag
                              key={emailIndex}
                              closable
                              closeIcon={<CloseIcon />}
                              onClose={() =>
                                !isSettingsDisabled ? removeSignatureEmail(emailIndex) : () => { }
                              }>
                              {email}
                            </Tag>
                          ))}
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={24}>
                    <div className="input-container">
                      <div className="form-item margin-bottom">
                        <label className="form-label">Enable download in preview</label>
                        <Select
                          value={
                            settings.allowPreviewDownload !== null
                              ? settings.allowPreviewDownload
                              : true
                          }
                          onChange={(val) => handleFieldsChange('allowPreviewDownload', val)}
                          className="select-custom-suffix"
                          disabled={isSettingsDisabled}>
                          <Option key={1} value={true}>
                            {'Yes'}
                          </Option>
                          <Option key={2} value={false}>
                            {'No'}
                          </Option>
                        </Select>
                      </div>
                      <div className="form-item input-no-bottom">
                        <label className="form-label">
                          <span>
                            AI Assistant{' '}
                            <Tooltip
                              overlayClassName="wizard-tooltip-email settings-tip"
                              title={
                                <>
                                  Enable AI assistant in your proposals. <br />
                                  Only supports English language at the moment.
                                </>
                              }
                              color="black"
                              key="white">
                              <InfoIcon />
                            </Tooltip>
                            <span className="beta-tag margin-start">BETA</span>
                          </span>
                        </label>
                        <div className={`ai-tool-toggle ${canUseAI ? '' : 'blur-overlay'}`}>
                          <Switch
                            id="ai-toggle"
                            checked={settings.enableAITool}
                            disabled={!canUseAI || isSettingsDisabled}
                            onChange={(value) => {
                              const aiToggle = value ? true : false;
                              handleFieldsChange('enableAITool', aiToggle);
                            }}
                          />
                          <label
                            htmlFor="ai-toggle"
                            className={`check-label ${settings.enableAITool ? 'active' : ''}`}>
                            {''}
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Col>

                {/* <Col md={12} lg={12} xl={12} className="input-container">
                    <div className="form-item">
                      <label className="form-label">Contact button visibility</label>
                      <Select
                        value={settings?.contactButton}
                        onChange={(val) => handleFieldsChange('contactButton', val)}
                        suffixIcon={<CaretDownOutlined color="red" />}
                        disabled={isSettingsDisabled}>
                        {Visibility.map((el) => (
                          <Option key={el.value} value={el.value}>
                            {el.text}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Col>

                  <Col md={12} lg={12} xl={12} className="input-container">
                    <div className="form-item">
                      <label className="form-label">Call button visibility</label>
                      <Select
                        value={settings?.callButton}
                        onChange={(val) => handleFieldsChange('callButton', val)}
                        suffixIcon={<CaretDownOutlined color="red" />}
                        disabled={isSettingsDisabled}>
                        {Visibility.map((el) => (
                          <Option key={el.value} value={el.value}>
                            {el.text}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Col> */}

                {/* <Col md={12} lg={12} xl={12}>
                    <Row className="settings-column extended">
                      <span className="upgrade">Upgrade</span>
                      <Row>
                        <Col className="input-container cname">
                          <div className="form-item">
                            <label className="form-label">CNAME</label>
                            <Input
                              value={settings ? settings.cname : ''}
                              placeholder="127.0.0.1"
                              onChange={(e) => handleFieldsChange('cname', e.target.value)}
                            />
                          </div>
                          <div />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="input-container domain">
                          <div className="form-item">
                            <label className="form-label">Domain</label>
                            <Input
                              value={settings ? settings.dns : ''}
                              placeholder="Example: https://proposals.youdomain.com"
                              onChange={(e) => handleFieldsChange('dns', e.target.value)}
                            />
                          </div>
                          <div />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="form-item">
                            <Button className="learn-more" type="primary">
                              LEARN MORE
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Row>
                  </Col> */}
              </Row>
            </div>
          </div>

          <div className="settings-form">
            <div className="signature-section">
              <div className="title-wrap">
                <h3>Signatures</h3>
                <p className="signature-section-info">
                  Define your default signature style and select signing options for prospects.
                </p>
              </div>
              <Row className="content signature-input">
                <Col xs={24} md={12} className="input-container">
                  <div className="form-item">
                    <label className="form-label">
                      <span>Your Signature type</span>
                      <Tooltip
                        overlayClassName="wizard-tooltip-email settings-tip"
                        title="Select the signature style you'd like to set as your default for all proposals."
                        color="black"
                        key="white">
                        <InfoIcon className="info-icon" />
                      </Tooltip>
                    </label>
                    <Select
                      value={settings?.sigtype}
                      onChange={(val) => handleFieldsChange('sigtype', val)}
                      className="select-custom-suffix"
                      disabled={isSettingsDisabled}>
                      {SignatureTypes.filter((SignatureType) =>
                        signatureOptions.includes(SignatureType.text.toLowerCase())
                      ).map((el) => (
                        <Option key={el.value} value={el.value}>
                          {el.text}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="form-item">
                    <Row
                      className={`settings-column extended ${
                        isSettingsDisabled && 'signature-editor-disabled'
                      }`}>
                      {signatureType === 'sigdraw' ? (
                        <div className="signature-canvas">
                          {settings?.signature && utils.checkValidURL(settings?.signature) ? (
                            <Image src={settings.signature} />
                          ) : (
                            <SignatureCanvas
                              ref={ref}
                              penColor="black"
                              canvasProps={{
                                width: '385px',
                                height: '150px',
                              }}
                              backgroundColor="white"
                            />
                          )}
                          <CloseIcon className="clear-button" onClick={handleClearCanvas} />
                        </div>
                      ) : null}
                      {signatureType === 'sigimage' ? (
                        <div style={{ width: '100%' }}>
                          {uploadingImage ? (
                            <div className="uploading-img">
                              <LoadingOutlined />
                              <span>Uploading...</span>
                            </div>
                          ) : (
                            <React.Fragment>
                              {!utils.checkValidURL(settings?.signature) ? (
                                <ContentLibraryIcon className="signature-img" />
                              ) : (
                                <img
                                  src={settings?.signature || '/images/sign.svg'}
                                  alt="signature"
                                  className="signature-img"
                                />
                              )}
                              <div className="signature-btn-wrapper">
                                <UploadOutlined />
                                <input
                                  type="file"
                                  name="myfile"
                                  onChange={(e) => handleImageUpload(e.target.files)}
                                  accept="image/x-png,image/bmp,image/jpeg,image/svg+xml"
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      ) : null}
                      {signatureType === 'sigtext' ? (
                        <Input
                          maxLength="30"
                          className="typed-signature"
                          value={
                            utils.checkValidURL(settings.signature)
                              ? user?.profile?.name
                              : settings?.signature
                          }
                          onChange={(e) => handleFieldsChange('signature', e.target.value)}
                        />
                      ) : null}
                    </Row>
                  </div>
                </Col>
                <Col xs={24} md={12} className="input-container">
                  <div className="form-item">
                    <label className="form-label">
                      <span>Prospect's Signature options</span>
                      <Tooltip
                        overlayClassName="wizard-tooltip-email settings-tip"
                        title="Select the signing options for your prospects."
                        color="black"
                        key="white">
                        <InfoIcon className="info-icon" />
                      </Tooltip>
                    </label>
                    <Select
                      className="settings-global select-custom-suffix"
                      value={settings?.sigOptions || []}
                      onChange={(val) => handleFieldsChange('sigOptions', val)}
                      suffixIcon={<CaretDownOutlined color="red" />}
                      mode="multiple"
                      disabled={isSettingsDisabled}
                      showArrow={true}>
                      {SignatureOptions.map((el) => (
                        <Option key={el.value} value={el.value}>
                          {el.text}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="settings-form">
            <div className="projects-type">
              <div className="title-wrap">
                <h3>Projects type</h3>
                <p className="projects-type-info">
                  Choose the type of your projects. It will add relevant pre-made content to your
                  proposals' wizard.
                </p>
              </div>
              <Row
                className={`content checkbox-grp ${isSettingsDisabled && 'checkbox-grp-disabled'}`}>
                {projectsType.map((el, index) => (
                  <Col xs={24} sm={12} md={8} key={el.key}>
                    <div
                      className={`project-checkbox ${clickedCheckbox[index] ? 'active' : ''}`}
                      onClick={() => handleCheckboxClick(index)}>
                      <Checkbox
                        checked={clickedCheckbox[index]}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCheckboxClick(index);
                        }}
                      />
                      <div className="label-icon">
                        <div className={`checkbox-icon ${el.key}-icon`} />
                        <p>{el.label}</p>
                      </div>
                    </div>
                  </Col>
                ))}
                {clickedCheckbox[clickedCheckbox.length - 1] ? (
                  <Col xs={24} lg={12}>
                    <div className="other-project">
                      <p>Other project type?</p>
                      <div>
                        <Input.TextArea
                          value={settings?.otherptype}
                          onChange={(e) => {
                            handleFieldsChange('otherptype', e.target.value);
                          }}
                          placeholder="Write small description"
                        />
                      </div>
                    </div>
                  </Col>
                ) : null}
              </Row>
            </div>
          </div>
          <div className="settings-form">
            <div className="pdf-options">
              <div className="title-wrap">
                <h3>PDF options</h3>
                <p className="section-info">
                  Personalize your proposal PDF footer for a professional touch.
                </p>
              </div>
              <Row className="content">
                <Col xs={24} lg={16} className="input-container">
                  <div className="form-item margin-bottom">
                    <div className="pdf-footer">
                      <Switch
                        id="show-footer"
                        checked={settings.showPdfFooter ? true : false}
                        disabled={isSettingsDisabled}
                        onChange={(value) => {
                          const footer = value ? true : false;
                          handleFieldsChange('showPdfFooter', footer);
                        }}
                      />
                      <label
                        htmlFor="show-footer"
                        className={`check-label ${settings.showPdfFooter ? 'active' : ''}`}>
                        Show PDF footer
                      </label>
                    </div>
                  </div>
                  <div className="form-item margin-bottom">
                    <label className="form-label">
                      <span>Title</span>
                      <MultiSelect
                        filterList={footerVariables}
                        disabled={isSettingsDisabled}
                        menuClassName={'dashboard-multi-select-menu'}
                        onChangeFilter={(val) => handleFieldsChange('footerVariable', val)}
                        onVisibleChangeHandle={(visible) =>
                          setActiveVariables(visible ? 'status' : '')
                        }
                        iconComponent={
                          <div>
                            <div className="ant-select ant-dropdown-trigger select-custom-suffix variables ant-select-single ant-select-show-arrow">
                              <div className="ant-select-selector">
                                <span className="ant-select-selection-item" title="Add variables">
                                  Add variables
                                </span>
                              </div>
                              <span
                                className="ant-select-arrow"
                                unselectable="on"
                                aria-hidden="true"
                                style={{ userSelect: 'none' }}>
                                <span
                                  role="img"
                                  aria-label="down"
                                  className="anticon anticon-down ant-select-suffix">
                                  <svg
                                    viewBox="64 64 896 896"
                                    focusable="false"
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    aria-hidden="true">
                                    <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                                  </svg>
                                </span>
                              </span>
                            </div>
                          </div>
                        }
                      />
                    </label>
                    <Input
                      type="text"
                      value={settings.pdfFooterVariables}
                      placeholder={'Enter footer title'}
                      disabled={isSettingsDisabled}
                      onChange={(e) => {
                        handleFieldsChange('pdfFooterVariables', e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-item mb-0">
                    <Row className="settings-column extended illustration">
                      <div className="illustration-wrap">
                        <div
                          className={`footer-illustration is-preview ${
                            settings.showPdfFooter ? 'footer-active' : 'footer-inactive'
                          }`}>
                          <div className="prop-body">
                            <p>
                              Instructed by the client. The services shall be developer's own
                              original idea...
                            </p>
                            <p>
                              Shall not include any third party intellectual software code with
                              templates <br />
                              for page layouts where a single use license was issued to the
                              buyer/customer...
                            </p>
                          </div>
                          <div className="prop-footer">
                            <hr className="divider" />
                            <div className="footer-text">{previewVariables}</div>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

Settings.defaultProps = {
  user: {
    profile: {
      name: '',
      language: 'english',
      currency: '$',
      priceSeperator: PriceSeparator[0].value,
      decimalPrecision: 2,
      redirectTo: '',
      dateFormat: DateFormats[0].value,
      redirectAfter: 0,
      contactButton: Visibility[0].value,
      callButton: Visibility[0].value,
      allowPreviewDownload: true,
      showPdfFooter: true,
    },
    otherptype: '',
    sigtype: 'sigdraw',
    sigOptions: '',
    contactOptions: [],
    signature: '',
    domain: '',
    ptype: '',
    uploadedFonts: [],
  },
};

Settings.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string,
    profile: PropTypes.shape({
      name: PropTypes.string,
      language: PropTypes.string,
      currency: PropTypes.string,
      priceSeperator: PropTypes.string,
      decimalPrecision: PropTypes.number,
      redirectTo: PropTypes.string,
      dateFormat: PropTypes.string,
      redirectAfter: PropTypes.number,
      contactButton: PropTypes.string,
      callButton: PropTypes.string,
      allowPreviewDownload: PropTypes.bool,
      showPdfFooter: PropTypes.bool,
      pdfFooterVariables: PropTypes.string,
    }),
    domain: PropTypes.string,
    ptype: PropTypes.string,
    otherptype: PropTypes.string,
    sigtype: PropTypes.string,
    sigOptions: PropTypes.string,
    contactOptions: PropTypes.instanceOf(Array),
    signature: PropTypes.string,
    uploadedFonts: PropTypes.instanceOf(Array),
  }),
};

export default Settings;
