import React, { useState, useEffect } from 'react';
import { Modal, Button, message } from 'antd';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { SelectFiles } from './components';
import { ADD_PROPOSAL_ATTACHMENTS } from 'graphql/mutations/proposalMutation';
import { ADD_LIBRARY_MEDIA } from 'graphql/mutations/contentLibraryMutations';
import { PROPOSAL_SUBSCRIPTION_TOPIC } from 'constants/index';
import { resizeImageForS3 } from 'utils/uploadToS3';

import Popconfirm from 'components/PopConfirm';
import CloseIcon from 'components/Icons/CloseIcon';
import Uploader from 'components/Uploader';

import './AttachmentModal.scss';
import FileCopier from 'components/FileCopier';
import { ContentLibrary } from 'pages/Proposal/components/RichEditor/RichEditorModals/GalleryEditModal/components';

const AttachmentModal = ({ visible, showAttachmentModal, prop, setProp, propRef, isTemplate }) => {
  const [upload, uploadStatus] = Uploader();
  const [selectedItems, setSeletedItem] = useState({});
  const [libraryModal, setLibraryModal] = useState(false);
  const [copy, copying] = FileCopier();
  const [showProgress, toggleShowProgress] = useState(false);
  const [status, setStatus] = useState('');

  const [showPropModal, setShowPropModal] = useState(visible);
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleKeyCommand = (e) => {
    if (e.key === 'Escape') {
      setShowModal(true);
      const isNotCombinedKey = !(e.ctrlKey || e.altKey || e.shiftKey);
      if (isNotCombinedKey) {
        makeEscapeModal(true);
      }
    }
  };

  const makeEscapeModal = (showModal) => {
    setShowPromptModal(showModal);
    setShowModal(showModal);
  };

  useEffect(() => {
    if (!showPromptModal) {
      window.addEventListener('keydown', handleKeyCommand, true);
    } else {
      window.removeEventListener('keydown', handleKeyCommand, true);
    }
    setShowPromptModal(!showPromptModal); // eslint-disable-next-line
  }, []);

  const [addAttachment] = useMutation(ADD_PROPOSAL_ATTACHMENTS, {
    onCompleted: ({ addProposalAttachment }) => {
      propRef.current = { ...prop, ...addProposalAttachment };
      setProp({ ...prop, ...addProposalAttachment });
    },
  });

  const [addContentMedia] = useMutation(ADD_LIBRARY_MEDIA);

  const [files, setFiles] = useState([]);

  const attachFiles = async () => {
    let attachmentsFiles = [];
    toggleShowProgress(true);
    try {
      if (libraryModal) {
        const selectedFiles = Object.values(selectedItems);
        const attachments = [];
        for (let fileIndex = 0; fileIndex < selectedFiles.length; fileIndex++) {
          const url = await copy({
            fromUrl: selectedFiles[fileIndex].src,
            prefix: `props/${prop._id}`,
          });
          attachments.push({
            url,
            type: selectedFiles[fileIndex].type,
            name: selectedFiles[fileIndex].name,
            size: 4500,
          });
        }
        await addAttachment({
          variables: {
            proposalId: prop._id,
            attachments,
            topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${prop?.channel || prop?.auid || prop?.uid}`,
            type: isTemplate ? 'template' : 'proposal',
          },
        });
      } else {
        let resizeErrors = '';

        for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
          try {
            const reSizedImage = await resizeImageForS3({
              file: files[fileIndex],
              path: 'attachments',
              size: {},
            });
            attachmentsFiles.push(reSizedImage);
          } catch (error) {
            resizeErrors = error.message;
            break;
          }
        }

        if (resizeErrors) {
          setFiles([]);
          return message.error(resizeErrors);
        }

        const attachments = await upload(attachmentsFiles, `props/${prop._id}/attachments`);

        setStatus(`Attaching files to proposal`);

        await addAttachment({
          variables: {
            proposalId: prop._id,
            attachments: attachments.map(({ url }, i) => ({
              url,
              type: files[i].type,
              name: files[i].name,
              size: files[i].size,
            })),
            topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${prop?.channel || prop?.auid || prop?.uid}`,
            type: isTemplate ? 'template' : 'proposal',
          },
        });

        for (const attachment of attachments) {
          const media = {
            src: attachment.url,
            uploadedBy: 'user',
            type: attachment.type,
            name: attachment.filename,
          };

          await addContentMedia({
            variables: {
              media,
              folderKey: '',
            },
          });
        }
      }
      toggleShowProgress(false);
    } catch (error) {
      message.error(error.message);
      setStatus('');
    } finally {
      toggleShowProgress(false);
    }
  };

  const handleSave = async () => {
    await attachFiles();
    showAttachmentModal(false);
  };

  return (
    <>
      <Modal
        visible={showPropModal}
        wrapClassName={`simple-info-modal rich-editor-gallery-edit ${
          !libraryModal ? 'select-box attachment-modal ' : ''
        } ${libraryModal ? 'library-modal' : ''}`}
        title={
          <div className="rich-editor-price-edit-header">
            <h3 className="title">{libraryModal ? 'Library' : 'Upload Files'}</h3>
          </div>
        }
        closeIcon={<CloseIcon />}
        onCancel={() => setShowModal(true)}
        onOk={uploadStatus.status !== 'uploading' && handleSave}
        footer={[
          <Button
            key="cancel"
            className="button cancel"
            type="primary"
            disabled={uploadStatus.status === 'uploading' || showProgress}
            onClick={() =>
              uploadStatus.status !== 'uploading' &&
              (libraryModal ? setLibraryModal(false) : showAttachmentModal(false))
            }>
            {libraryModal ? 'BACK' : 'CANCEL'}
          </Button>,
          <Button
            key="confirm"
            className="button confirm"
            type="primary"
            onClick={uploadStatus.status !== 'uploading' && handleSave}
            disabled={
              uploadStatus.status === 'uploading' ||
              (!libraryModal && !files.length) ||
              (libraryModal && !Object.keys(selectedItems).length)
            }>
            {(!libraryModal && !files.length) ||
            (libraryModal && !Object.values(selectedItems).length)
              ? 'ADD Files'
              : 'Attach'}
          </Button>,
        ]}>
        {!libraryModal && (
          <>
            <SelectFiles
              dropImage={(files) => setFiles(files)}
              uploadStatus={uploadStatus}
              showProgress={showProgress}
              imageName={status}
              files={files}
            />
            <p>Or</p>
            <Button className="button cancel" type="primary" onClick={() => setLibraryModal(true)}>
              Choose From Library
            </Button>
          </>
        )}
        {libraryModal && (
          <ContentLibrary
            selectedItems={selectedItems}
            setSeletedItem={setSeletedItem}
            copying={copying}
            showSelectedFiles={true}
          />
        )}
      </Modal>
      <>
        {showModal && (
          <Popconfirm
            visible={showModal}
            title={'Do you want to exit?'}
            closable={false}
            cancelText={'Continue'}
            confirmText={'Yes, Exit'}
            onCancel={() => {
              setShowModal(false);
              setShowPropModal(true);
            }}
            onConfirm={() => uploadStatus.status !== 'uploading' && showAttachmentModal(false)}
            footer={null}
          />
        )}
      </>
    </>
  );
};

AttachmentModal.propTypes = {
  prop: PropTypes.instanceOf(Object).isRequired,
  visible: PropTypes.bool.isRequired,
  showAttachmentModal: PropTypes.func.isRequired,
  setProp: PropTypes.func.isRequired,
  isTemplate: PropTypes.bool,
};

export default AttachmentModal;
