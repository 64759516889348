/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Empty, message } from 'antd';
import { useMutation, useQuery, useLazyQuery, useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';

import { getFilteredTemplates } from 'helpers/contentlibrary';
import {
  GET_ALL_TEMPLATES_LIBRARIES,
  GET_TREEVIEW_DATA,
} from 'graphql/queries/contentLibraryQueries';
import {
  UPDATE_CONTENT_LIBRARY,
  CONTENT_LIBRARY_TEMPLATE_DELETE,
} from 'graphql/mutations/contentLibraryMutations';
import { CREATE_PROPOSAL_MUTATION } from 'graphql/mutations/proposalMutation';
import { PROPOSAL_SUBSCRIPTION_TOPIC, TEMPLATES_LIMIT } from 'constants/index';
import { mondayBoardIdVar, templatesVar, templatesCountVar } from 'graphql/cache';
import { ContentLoader } from '../ContentLoader/ContentLoader';

import ConfirmModal from 'components/ConfirmModal';
import CreateDuplicateProposalModal from 'components/CreateDuplicateProposalModal';
import MoveLibraryModal from 'pages/ContentLibrary/ContentLibraryModals/MoveLibraryModal';
import ShareWithModal from 'components/ShareWithModal';
import TemplateCard from '../TemplateCard/TemplateCard';
import PreviewProposalModal from '../PreviewProposalModal/PreviewProposalModal';
import ContentLibraryTree from '../ContentLibraryTree/ContentLibraryTree';
import ContentLibraryModals from '../../ContentLibraryModals';
import { sanitizeObject } from 'utils/xss';

const TemplateTab = ({
  disabledFilter,
  filterItems,
  searchValue,
  deletedTemplates,
  user,
  config,
  loadConfig,
  saveUser,
  teamMembers,
  sortValue,
  isLocked,
  onFolderChange,
}) => {
  const [createModal, showCreateModal] = useState(false);
  const [previewModal, showPreviewModal] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [selectedFolderKey, changeSelectedFolderKey] = useState('');
  const [confirmModal, showConfirmModal] = useState(false);
  const [shareModal, showShareModal] = useState(false);
  const [moveModal, showMoveModal] = useState(false);
  const [showDuplicateTemplate, showDuplicateTemplateModal] = useState(false);
  const [selectedTemplateEditValue, setSelectedTemplateEditValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [starredTemplates, setStarredTemplates] = useState([]);
  const [dontOverWriteHeader, setDontOverWriteHeader] = useState(false);

  const templates = useReactiveVar(templatesVar || []);
  const templatesCount = useReactiveVar(templatesCountVar);

  const contentLibraryTreeRef = useRef();
  /*
    This query in content library tree is getting executed after get all templates query which is causing no templates to load
  */
  useQuery(GET_TREEVIEW_DATA, {
    variables: {
      type: 'templates',
    },
  });

  const [fetchAllTemplates, { refetch, fetchMore, loading }] = useLazyQuery(
    GET_ALL_TEMPLATES_LIBRARIES,
    {
      variables: {
        limit: TEMPLATES_LIMIT * (window.innerHeight >= 1250 ? 2 : 1),
        offset: 0,
        search: searchValue,
        key: selectedFolderKey,
        filterItem: filterItems?.find((filterItem) => filterItem.checked)?.key,
        sort: sortValue,
      },
      fetchPolicy: 'no-cache',
      skip: !!templates?.length,
      onCompleted: ({ fetchContentLibraryTemplatesItems }) => {
        if (fetchContentLibraryTemplatesItems?.templates) {
          templatesVar(fetchContentLibraryTemplatesItems?.templates?.slice());
          templatesCountVar(fetchContentLibraryTemplatesItems.templatesCount);
        }
      },
    }
  );

  useEffect(() => {
    fetchAllTemplates();
  }, [fetchAllTemplates]);

  const refetchTemplates = useCallback(
    async ({ key, search, filter, sort }) => {
      if (user?.paymentStatus?.canLock) {
        return;
      }
      const refetchTemplateData = await refetch?.({
        limit: TEMPLATES_LIMIT * (window.innerHeight >= 1250 ? 2 : 1),
        offset: 0,
        search,
        filterItem: filter?.find((filterItem) => filterItem.checked)?.key,
        key,
        sort,
      });
      if (refetchTemplateData?.data?.fetchContentLibraryTemplatesItems) {
        templatesVar(refetchTemplateData?.data?.fetchContentLibraryTemplatesItems.templates);
        templatesCountVar(
          refetchTemplateData.data.fetchContentLibraryTemplatesItems.templatesCount
        );
      }
    },
    [refetch, user?.paymentStatus?.canLock]
  );

  const fetchMoreTemplates = async (type = '') => {
    let page = offset;

    // reset offsets when loaded templates resets
    if (
      templates.length < templatesCount &&
      Math.ceil(templatesCount / TEMPLATES_LIMIT) === page &&
      !isLoadingMore
    ) {
      templatesCountVar(templates.length);
      setOffset(0);
    }

    if (
      (templatesCount === null || Math.ceil(templatesCount / TEMPLATES_LIMIT) > page) &&
      fetchMore &&
      templates.length <= templatesCount &&
      !isLoadingMore
    ) {
      setIsLoadingMore(true);

      const fetchMoreTemplateData = await fetchMore({
        variables: {
          limit: TEMPLATES_LIMIT * (window.innerHeight >= 1250 && !page ? 2 : 1),
          offset: page + 1,
          search: searchValue,
          filterItem: filterItems?.find((filterItem) => filterItem.checked)?.key,
          sort: sortValue,
        },
      });

      templatesVar([
        ...templates,
        ...fetchMoreTemplateData?.data?.fetchContentLibraryTemplatesItems.templates,
      ]);
      templatesCountVar(
        fetchMoreTemplateData.data.fetchContentLibraryTemplatesItems.templatesCount
      );
      setOffset(offset + 1);
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    const mainContent = document.querySelector('.template-scroll-wrapper');
    async function onScroll() {
      if (mainContent?.scrollTop + mainContent?.offsetHeight + 1 > mainContent?.scrollHeight) {
        fetchMoreTemplates();
      }
    }

    mainContent?.addEventListener('scroll', onScroll);

    return () => {
      mainContent?.removeEventListener('scroll', onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItems, offset, searchValue, templates, isLoadingMore]);

  const [updateContentItem] = useMutation(UPDATE_CONTENT_LIBRARY, {
    onCompleted() {
      refetchTemplates({
        key: selectedFolderKey,
        search: searchValue,
        filter: filterItems,
        sort: sortValue,
      });
    },
  });

  const [createProposal] = useMutation(CREATE_PROPOSAL_MUTATION, {
    onCompleted: ({ createProposal }) => {
      showCreateModal(false);
      setIsLoading(false);
      if (createProposal) {
        history.push(`/d/${createProposal.pid}`);
      } else {
        message.error(`Problem creating proposal`);
      }
    },
    onError: (error) => {
      setIsLoading(false);
      console.log(error, 'error duplicating');
      message.error(`Problem creating proposal ${error.message}`);
    },
  });

  const [deleteTemplate] = useMutation(CONTENT_LIBRARY_TEMPLATE_DELETE, {
    onCompleted() {
      templatesVar(templates.filter((template) => template._id !== selectedTemplateId));
      message.success('Template deleted successfully');
      showConfirmModal(false);
      setSelectedTemplateId('');
    },
    onError() {
      message.error('Template deletion failed');
      showConfirmModal(false);
    },
  });

  useEffect(() => {
    if (searchValue || user.deletedTemplates) {
      refetchTemplates({
        key: selectedFolderKey,
        search: searchValue,
        filter: filterItems,
        sort: sortValue,
      });
    }
  }, [
    searchValue,
    filterItems,
    sortValue,
    refetchTemplates,
    selectedFolderKey,
    user.deletedTemplates,
  ]);

  // eslint-disable-next-line no-unused-vars
  const onSelect = (selectedKeys) => {
    changeSelectedFolderKey(selectedKeys[0]);
    onFolderChange(selectedKeys[0]);
  };

  const history = useHistory();

  const filteredTemplates = getFilteredTemplates({ templates });

  const { starred: starredItems, all: allItems } = filteredTemplates;

  const hideOrEnableTemplateFromUser = ({ templateId }) => {
    let deletedTemplates = [...user.deletedTemplates] || [];

    if (!deletedTemplates.includes(templateId)) {
      deletedTemplates.push(templateId);
    } else {
      deletedTemplates = deletedTemplates.filter((e) => e !== templateId);
    }

    saveUser({
      variables: {
        user: {
          id: user._id,
          deletedTemplates,
        },
      },
    });
  };

  const starOrUnstar = ({ starred, id }) => {
    updateContentItem({
      variables: {
        id: id,
        type: 'templates',
        item: { starred },
      },
    });
  };

  const confirmDeleteTemplate = () => {
    deleteTemplate({
      variables: {
        _id: selectedTemplateId,
        folderKey: selectedFolderKey,
      },
    });
  };

  const createProposalFromTemplate = ({ values, proposal }) => {
    setIsLoading(true);
    const { _id, pid } = proposal;

    values = sanitizeObject(values);

    const {
      client_name: newClientName,
      project_name: newProjectName,
      client_email: newClientMail,
      client_company: newClientCompany,
      monday_item_id: mondayItemId,
    } = values;

    createProposal({
      variables: {
        pid: _id,
        topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${user?.teamId || user?._id}`,
        template: {
          newProjectName: newProjectName,
          newClientName: newClientName,
          newClientMail: newClientMail || '',
          newClientExtra: {
            company: newClientCompany || '',
          },
        },
        additionalInfo: {
          monday: {
            boardId: mondayBoardIdVar(),
            itemId: mondayItemId,
          },
          dontOverWriteHeader,
        },
      },
    });
  };

  const onClickMenuItem = async ({
    actionType,
    templateId,
    starred,
    templateData,
    templateName,
  }) => {
    if (actionType === 'delete') {
      setSelectedTemplateId(templateId);
      showConfirmModal(true);
    } else if (actionType === 'hideorenable') {
      hideOrEnableTemplateFromUser({ templateId });
    } else if (actionType === 'starOrUnstar') {
      starOrUnstar({ starred, id: templateId });
    } else if (actionType === 'use_template') {
      const template = templates.find((item) => item._id === templateId);
      setSelectedTemplate(template);
      showCreateModal(true);
    } else if (actionType === 'use_preview_as_template') {
      const template = templates.find((item) => item._id === templateId);
      showPreviewModal(false);
      setSelectedTemplate(template);
      showCreateModal(true);
    } else if (actionType === 'preview') {
      setSelectedTemplate(templateData);
      showPreviewModal(true);
    } else if (actionType === 'edit_template') {
      history.push(`/t/${templateId}`);
    } else if (actionType === 'rename') {
      setSelectedTemplateId(templateId);
      setSelectedTemplateEditValue(templateName);
    } else if (actionType === 'save') {
      await updateContentItem({
        variables: {
          id: templateId,
          type: 'templates',
          item: { templateName: selectedTemplateEditValue },
        },
      });
      setSelectedTemplateId('');
      setSelectedTemplateEditValue('');
    } else if (actionType === 'share') {
      const template = templates.find((item) => item._id === templateId);
      setSelectedTemplate(template);
      showShareModal(true);
    } else if (actionType === 'move') {
      const template = templates.find((item) => item._id === templateId);
      setSelectedTemplate(template);
      showMoveModal(true);
    } else if (actionType === 'duplicate') {
      const template = templates.find((item) => item._id === templateId);
      setSelectedTemplate(template);
      showDuplicateTemplateModal(true);
    }
  };

  const isTemplatesEmpty =
    starredTemplates === 'onlyStarred'
      ? !starredItems.length
      : !(starredItems.length || allItems.length);

  return (
    <div className="content-library-item">
      <ContentLibraryTree
        ref={contentLibraryTreeRef}
        onStarred={(starred) => {
          setStarredTemplates(starred);
        }}
        onSelect={onSelect}
        type="templates"
        onUpdate={() =>
          refetchTemplates({
            key: selectedFolderKey,
            search: searchValue,
            filter: filterItems,
            sort: sortValue,
          })
        }
        userId={user && user._id}
        userRole={user?.roles && user?.roles[0]}
        isLocked={isLocked}
      />

      <div className="split-d2 template-scroll-wrapper">
        {loading ? (
          <ContentLoader />
        ) : (
          <>
            {starredItems.length ? (
              <div className="stared-header">
                MY STARRED TEMPLATES
                <Badge className="star-badge" count={starredItems.length} />
              </div>
            ) : null}
            <React.Fragment>
              {isTemplatesEmpty && (
                <div className="item-row empty-wrapper">
                  <Empty
                    className="empty-view"
                    description="Looks like you have no template files yet"
                  />
                </div>
              )}

              {starredItems?.length > 0 && (
                <div className="item-row template-cards">
                  {starredItems.map((item) => {
                    return (
                      <TemplateCard
                        onDragStart={(ev) => {
                          ev.dataTransfer.setData('data', JSON.stringify(item));
                        }}
                        templateUserId={item.uid}
                        templateAuid={item.auid}
                        templateId={item._id}
                        title={item.templateName ? item.templateName : item.project.name}
                        thumbnail={item.templateUrl}
                        key={item.pid}
                        description={item.description}
                        starOrUnstar={starOrUnstar}
                        isStarred
                        disabledFilter={disabledFilter}
                        onClickMenuItem={onClickMenuItem}
                        config={config}
                        user={user}
                        itemEditable={selectedTemplateId === item._id}
                        setEditValue={setSelectedTemplateEditValue}
                        isLocked={isLocked}
                        variables={item?.draft?.variables}
                      />
                    );
                  })}
                </div>
              )}

              {starredTemplates !== 'onlyStarred' && starredItems.length && allItems.length ? (
                <div className="divider-line" />
              ) : null}

              <div className="item-row template-cards">
                {starredTemplates !== 'onlyStarred' &&
                  allItems.map((item) => {
                    return (
                      <TemplateCard
                        onDragStart={(ev) => {
                          ev.dataTransfer.setData('data', JSON.stringify(item));
                        }}
                        title={item.templateName ? item.templateName : item?.project?.name}
                        thumbnail={item.templateUrl}
                        key={item._id}
                        description={item.description}
                        starOrUnstar={starOrUnstar}
                        templateId={item._id}
                        templateUserId={item.uid}
                        templateAuid={item.auid}
                        disabledFilter={disabledFilter}
                        onClickMenuItem={onClickMenuItem}
                        config={config}
                        user={user}
                        itemEditable={selectedTemplateId === item._id}
                        setEditValue={setSelectedTemplateEditValue}
                        isLocked={isLocked}
                        variables={item?.draft?.variables}
                      />
                    );
                  })}
              </div>

              {isLoadingMore && <ContentLoader length={4} />}
            </React.Fragment>
          </>
        )}
      </div>
      {createModal && (
        <CreateDuplicateProposalModal
          modalType="Create"
          user={user}
          visible={createModal}
          isLoading={isLoading}
          onCancel={() => showCreateModal(false)}
          selectedProposal={selectedTemplate}
          onConfirm={createProposalFromTemplate}
          isFromTemplate={true}
          dontOverWriteHeader={dontOverWriteHeader}
          setDontOverWriteHeader={setDontOverWriteHeader}
        />
      )}
      {previewModal && (
        <PreviewProposalModal
          onClickMenuItem={onClickMenuItem}
          onCancel={() => showPreviewModal(false)}
          template={selectedTemplate}
          config={config}
          loadConfig={loadConfig}
          user={user}
          isLocked={isLocked}
          title={
            selectedTemplate.selectedTemplateName ||
            selectedTemplate.titletext ||
            (selectedTemplate.draft &&
              selectedTemplate.draft.header &&
              selectedTemplate.draft.header.title) ||
            (selectedTemplate.project && selectedTemplate.project.name)
          }
        />
      )}
      {shareModal && (
        <ShareWithModal
          onCancel={() => showShareModal(false)}
          template={selectedTemplate}
          teamMembers={teamMembers}
          visible={shareModal}
          isLocked={isLocked}
        />
      )}
      {confirmModal && (
        <ConfirmModal
          title="Delete Template?"
          body="This action cannot be undone"
          visible={confirmModal}
          cancelText="CANCEL"
          confirmText="YES, DELETE"
          onCancel={() => showConfirmModal(false)}
          onConfirm={confirmDeleteTemplate}
        />
      )}
      {showDuplicateTemplate && (
        <MoveLibraryModal
          visible={showDuplicateTemplate}
          onCancel={() => showDuplicateTemplateModal(false)}
          template={selectedTemplate}
          isDashboard={true}
          selectedFolderKey={selectedFolderKey}
          selectedProposal={selectedTemplate}
          refetchTemplates={() =>
            refetchTemplates({
              key: selectedFolderKey,
              search: searchValue,
              filter: filterItems,
              sort: sortValue,
            })
          }
          isTemplate={true}
        />
      )}
      <ContentLibraryModals
        moveModal={moveModal}
        showMoveModal={showMoveModal}
        template={selectedTemplate}
        selectedFolderKey={selectedFolderKey}
        refetchTemplates={() =>
          refetchTemplates({
            key: selectedFolderKey,
            search: searchValue,
            filter: filterItems,
            sort: sortValue,
          })
        }
        refetchContentLibraryTree={async () => {
          if (contentLibraryTreeRef?.current) {
            await contentLibraryTreeRef.current.reloadItems();
          }
        }}
      />
    </div>
  );
};

TemplateTab.defaultProps = {
  searchValue: '',
  loading: false,
  disabledFilter: false,
  templates: [],
  deletedTemplates: [],
  config: {},
  isLocked: false,
};

TemplateTab.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.shape({})),
  deletedTemplates: PropTypes.arrayOf(PropTypes.string),
  filterItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchValue: PropTypes.string,
  loading: PropTypes.bool,
  disabledFilter: PropTypes.bool,
  user: PropTypes.instanceOf(Object).isRequired,
  config: PropTypes.instanceOf(Object),
  saveUser: PropTypes.func.isRequired,
  sortValue: PropTypes.string.isRequired,
  teamMembers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLocked: PropTypes.bool,
  onFolderChange: PropTypes.func.isRequired,
};

export default TemplateTab;
