import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Popover, Col } from 'antd';

import './Tour.scss';

const Tour = ({ open, onClose, steps, indicatorsRender, visibleChange, icon, onSubmit }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const backdropRef = useRef();

  useEffect(() => {
    const createBackdrop = () => {
      if (!backdropRef.current) {
        const backdrop = document.createElement('div');
        backdrop.className = 'tour-backdrop';
        backdropRef.current = backdrop;
        const mainElement = document.querySelector('main');
        if (mainElement && mainElement.parentNode) {
          mainElement.parentNode.insertBefore(backdrop, mainElement.nextSibling);
        }
      } else {
        backdropRef.current.style.display = 'block';
      }
    };

    const removeBackdrop = () => {
      if (backdropRef.current) {
        backdropRef.current.style.display = 'none';
      }
    };

    if (open) {
      createBackdrop();
    } else {
      removeBackdrop();
    }

    return () => {
      if (backdropRef.current) {
        backdropRef.current.remove();
        backdropRef.current = null;
      }
    };
  }, [open]);

  //   TODO Improve navigation functionality, show ref component/indicators.
  //   const nextStep = () => {
  //     if (currentStep < steps.length - 1) {
  //       setCurrentStep(currentStep + 1);
  //     }
  //   };

  //   const prevStep = () => {
  //     if (currentStep > 0) {
  //       setCurrentStep(currentStep - 1);
  //     }
  //   };

  const handleClose = () => {
    onClose();
    setCurrentStep(0);
  };

  if (!open || steps.length === 0) return null;

  const { title, description, placement } = steps[currentStep];

  return (
    <>
      <Popover
        content={
          <Col>
            <div className="close-btn" onClick={handleClose} />
            <Col>
              <h3>{title}</h3>
              <p>{description}</p>
              <Button size="middle" type="button" className="button" onClick={onSubmit}>
                {` Save As Template`}
              </Button>
              {indicatorsRender && indicatorsRender(currentStep, steps.length)}
            </Col>
          </Col>
        }
        placement={placement}
        overlayClassName="tour-overlay"
        trigger={'click'}
        visible={open}
        onVisibleChange={visibleChange}
      />
      <div className="tour-focus">{icon}</div>
    </>
  );
};

Tour.defaultProps = {
  open: false,
};

Tour.propTypes = {
  open: PropTypes.bool,
};

export default Tour;
