/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Divider, Tabs, Input, Tooltip, Button } from 'antd';
import { useReactiveVar, useLazyQuery, useMutation } from '@apollo/client';

import { userVar, teamMembersVar } from 'graphql/cache';
import utils from 'utils/utils';
import PageHeader from 'components/PageHeader/PageHeader';

import {
  SearchIcon,
  FilterIcon,
  SortIcon,
  DoubleArrowIcon,
  UploadIcon,
  CloseIcon,
} from 'components/Icons';

import MultiSelect from 'components/MultiSelect';
import UploadContentLibraryItem from 'components/UploadContentLibraryItem/UploadContentLibraryItem';
import TemplateTab from './components/TemplateTab/TemplateTab';
import CoverTab from './components/CoverTab/CoverTab';
import SectionTab from './components/SectionTab/SectionTab';
import MediaTab from './components/MediaTab/MediaTab';
import HeaderTab from './components/HeaderTab/HeaderTab';
import Loader from 'components/Loader';
import IconButton from 'components/IconButton';

import { preloadFont, addFontFaceRule } from 'helpers/proposal';
import { TEMPLATE_FILTER_ITEMS, COVERS_FILTER_ITEMS } from 'constants/index';
import { GET_CONFIGURATION } from 'graphql/queries/configurationQueries';
import { USER_UPDATE } from 'graphql/mutations/userMutations';

import './ContentLibrary.scss';
import './components/ContentLibraryItem/ContentLibraryItem.scss';
import './components/ContentCard/ContentCard.scss';
import useWindowDimensions from 'hooks/useWindowDimensions';

const { TabPane } = Tabs;

const ContentLibrary = () => {
  const user = useReactiveVar(userVar);
  const teamMembers = useReactiveVar(teamMembersVar);

  const coversRef = useRef();
  const mediasRef = useRef();

  const { width } = useWindowDimensions();
  const handlePreloadCustomFont = (user) => {
    const customFonts = user?.uploadedFonts || [];
    customFonts.forEach(({ family, url, weights, format }) => {
      preloadFont(url);
      addFontFaceRule(family, url, weights, format);
    });
  };

  const getSavedFilter = () => {
    return !user?.templateFilter
      ? TEMPLATE_FILTER_ITEMS
      : TEMPLATE_FILTER_ITEMS.map((item) =>
          item.id === user.templateFilter ? { ...item, checked: true } : { ...item, checked: false }
        );
  };

  const [selectedTab, setSelectedTab] = useState('templates');
  const [activeKey, setActiveTab] = useState(utils.getQueryStringValue('tab') || '1');
  const [selectedCoverType, setCoverType] = useState('top');
  const [selectedFolderKey, setFolderKey] = useState('');
  const [hideFolderMenu, setHideFolderMenu] = useState(true);
  const [config, setConfig] = useState({});
  const [showUploadModal, showOrHidesUploadModal] = useState(false);
  const showFilter = {
    templates: true,
    covers: true,
    headers: false,
    section: false,
    media: false,
  };
  const [searchValue, setSerachValue] = useState('');
  const [showSearchBox, showHideSearchBox] = useState(false);

  const [filterItems, setFilterItems] = useState(
    utils.getQueryStringValue('tab') === 'covers' ? COVERS_FILTER_ITEMS : getSavedFilter()
  );
  const [sortValue, setSortValue] = useState('desc');
  const [placeHolderText, setPlaceHolderText] = useState('Search templates...');

  const [loadConfig] = useLazyQuery(GET_CONFIGURATION, {
    fetchPolicy: 'cache-and-network',
    skip: config,
    onCompleted: (data) => {
      setConfig(data?.fetchConfiguration || {});
    },
  });

  const [saveUser] = useMutation(USER_UPDATE, {
    async onCompleted({ updateUser }) {
      userVar(updateUser);
    },
  });

  useEffect(() => {
    onChangeTab(utils.getQueryStringValue('tab'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !Object.keys(config)?.length &&
      (selectedTab === 'templates' || selectedTab === 'headers' || selectedTab === 'sections')
    ) {
      loadConfig({
        variables: {
          language: 'english',
        },
      });
    }
  }, [config, loadConfig, selectedTab]);

  useEffect(() => {
    handlePreloadCustomFont(user);
  }, [user]);

  useEffect(() => {
    document.title = 'Prospero - Content Library';
  });

  const onChangeTab = (key) => {
    if (!key) {
      key = '1';
    }

    if (parseInt(key) === 1) {
      setFilterItems(getSavedFilter());
      setSelectedTab('templates');
    } else if (parseInt(key) === 2) {
      setSelectedTab('covers');
      setFilterItems(COVERS_FILTER_ITEMS);
      setPlaceHolderText('Search Covers...');
    } else if (parseInt(key) === 3) {
      setSelectedTab('headers');
      setPlaceHolderText('Search Header...');
    } else if (parseInt(key) === 4) {
      setSelectedTab('sections');
      setPlaceHolderText('Search Sections...');
    } else {
      setSelectedTab('media');
      setPlaceHolderText('Search Media...');
    }
    setActiveTab(key);
    setHideFolderMenu(true);
  };

  const onChangeFilter = (selectedItem) => {
    let updatedFilterList;
    if (selectedTab === 'covers') {
      updatedFilterList = filterItems.map((item) =>
        item.id === selectedItem.id
          ? { ...item, checked: !selectedItem.checked }
          : { ...item, checked: selectedItem.checked }
      );
      const { key } = updatedFilterList.find((item) => item.checked);
      setCoverType(key);
    } else {
      updatedFilterList = filterItems.map((item) =>
        item.id === selectedItem.id
          ? { ...item, checked: !selectedItem.checked }
          : { ...item, checked: false }
      );
    }

    setFilterItems(updatedFilterList);
    if (selectedTab === 'templates') {
      saveUser({
        variables: {
          user: {
            id: user._id,
            templateFilter: selectedItem.id,
          },
        },
      });
    }
  };

  const onChangeSearch = (e) => {
    setSerachValue(e.target.value);
  };

  const onSearchIconClick = () => {
    showHideSearchBox(!showSearchBox);
    setSerachValue('');
  };

  const onClearFilter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onChangeFilter({ id: 0, name: 'All', key: 'all', checked: false });
  };

  const selectedItem = filterItems?.find(
    (filterItem) => filterItem?.checked && filterItem?.key !== 'all'
  );

  const toTitleCase = (str) => {
    return str?.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const tabOperations = (
    <div className="tab-icons-container">
      <Input
        className={`content-search-input ${showSearchBox ? 'showInput' : ''}`}
        placeholder={`Search ${toTitleCase(selectedTab)}...`}
        autoFocus
        value={searchValue}
        onChange={onChangeSearch}
        suffix={<CloseIcon onClick={onSearchIconClick} />}
      />
      <SearchIcon
        className={`content-search-icon hoverable ${showSearchBox ? 'hide-search' : ''}`}
        onClick={onSearchIconClick}
      />
      {showFilter[selectedTab] === true && (
        <React.Fragment>
          <Divider type="vertical" className="vertical-divider" />
          <MultiSelect
            filterList={filterItems}
            onChangeFilter={onChangeFilter}
            isRadio={true}
            showSelected
            iconComponent={
              selectedItem && selectedTab === 'templates' ? (
                <div className={`filter-icon hoverable ${selectedItem ? 'anticon-active' : ''}`}>
                  <FilterIcon />
                  <span className="text">{selectedItem?.name}</span>
                  <CloseIcon className="close-icon" onClick={onClearFilter} />
                </div>
              ) : (
                <FilterIcon className={`hoverable ${selectedItem ? 'anticon-active' : ''}`} />
              )
            }
          />
          <Divider type="vertical" className="vertical-divider" />

          <Tooltip
            title={`Sort by entry date in ${
              sortValue === 'asc' ? 'descending' : 'ascending'
            } order`}>
            <SortIcon
              className="content-sort-icon hoverable"
              onClick={() => (sortValue === 'asc' ? setSortValue('desc') : setSortValue('asc'))}
            />
          </Tooltip>
        </React.Fragment>
      )}
    </div>
  );

  const disabledFilter =
    selectedTab === 'templates'
      ? filterItems?.find((item) => item.key === 'disabled')?.checked
      : false;

  const onUploadClick = () => {
    showOrHidesUploadModal(true);
  };

  const cancelModal = () => {
    showOrHidesUploadModal(false);
  };

  const onFolderChange = (key) => {
    setFolderKey(key);
    setHideFolderMenu(key !== null);
  };

  if (!user) {
    return <Loader />;
  }

  const isLocked = user?.paymentStatus?.canLock || false;
  const isMobile = width < 767;

  return (
    <div className="container container-content-library">
      <div className="content-library">
        <PageHeader
          pageTitle="Your Content"
          showButton={
            <>
              {isMobile && tabOperations}
              {(selectedTab === 'covers' || selectedTab === 'media') && (
                <>
                  {isMobile ? (
                    <Button onClick={onUploadClick} className="create-new-button">
                      <UploadIcon className="upload-icon" />
                    </Button>
                  ) : (
                    <IconButton disabled={isLocked} text="UPLOAD" onButtonClick={onUploadClick} />
                  )}
                </>
              )}
            </>
          }
        />
        <Tabs
          activeKey={activeKey}
          tabBarExtraContent={!isMobile && tabOperations}
          onChange={onChangeTab}
          className={`content-library-tabs ${hideFolderMenu ? 'show-screen-2' : 'show-screen-1'}`}>
          <TabPane tab="Templates" key="1">
            <TemplateTab
              type="templates"
              teamMembers={teamMembers}
              filterItems={filterItems}
              disabledFilter={disabledFilter}
              searchValue={searchValue}
              sortValue={sortValue}
              user={user}
              deletedTemplates={user.deletedTemplates}
              saveUser={saveUser}
              config={config}
              loadConfig={loadConfig}
              onFolderChange={onFolderChange}
              isLocked={isLocked}
            />
          </TabPane>

          <TabPane tab="Covers" key="2">
            <CoverTab
              ref={coversRef}
              type="covers"
              filterItems={filterItems}
              autoExpandParent
              searchValue={searchValue}
              user={user}
              sortValue={sortValue}
              onFolderChange={onFolderChange}
              isLocked={isLocked}
            />
          </TabPane>

          <TabPane tab="Headers" key="3">
            <HeaderTab
              type="headers"
              filterItems={filterItems}
              searchValue={searchValue}
              user={user}
              config={config}
              loadConfig={loadConfig}
              onFolderChange={(key) => setFolderKey(key)}
              isLocked={isLocked}
            />
          </TabPane>

          <TabPane tab="Sections" key="4">
            <SectionTab
              type="sections"
              filterItems={filterItems}
              searchValue={searchValue}
              user={user}
              config={config}
              setConfig={setConfig}
              loadConfig={loadConfig}
              onFolderChange={onFolderChange}
              isLocked={isLocked}
            />
          </TabPane>

          <TabPane tab="Media" key="5">
            <MediaTab
              ref={mediasRef}
              type="media"
              filterItems={filterItems}
              searchValue={searchValue}
              user={user}
              sortValue={sortValue}
              onFolderChange={onFolderChange}
              isLocked={isLocked}
            />
          </TabPane>

          {isMobile && (
            <button className="tab-menu-opener" onClick={() => setHideFolderMenu(!hideFolderMenu)}>
              <DoubleArrowIcon />
            </button>
          )}
        </Tabs>
        <UploadContentLibraryItem
          type={selectedTab}
          selectedFolderKey={selectedFolderKey}
          showUploadModal={showUploadModal}
          cancelUploadModal={cancelModal}
          selectedCoverType={selectedCoverType}
          onSuccess={async () => {
            if (coversRef && coversRef.current) {
              await coversRef.current.reloadItems();
            }
            if (mediasRef && mediasRef.current) {
              await mediasRef.current.reloadItems();
            }
          }}
        />
      </div>
    </div>
  );
};

ContentLibrary.defaultProps = {
  templates: [],
  covers: [],
  sections: [],
  coversLibrary: [],
  config: null,
};

export default ContentLibrary;
