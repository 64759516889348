import PropTypes from 'prop-types';
import { useApolloClient, useReactiveVar } from '@apollo/client';
import { CachePersistor } from 'apollo3-cache-persist';
import { Avatar, Menu, Dropdown, Space } from 'antd';
import { useHistory } from 'react-router-dom';

import SignOutIcon from 'components/Icons/SignOutIcon';
import Path from 'routes/path';
import utils from 'utils/utils';
import { getUserShortName } from 'helpers/users';
import {
  isLoggedInVar,
  userProfileVar,
  userVar,
  proposalsVar,
  adminProfileVar,
  adminVar,
} from 'graphql/cache';
import { LOCKED_PAGES } from 'constants/index';

import './AccountMenu.scss';

const AccountMenu = ({ user, isLocked }) => {
  const { teamId } = user || {};
  const profile = useReactiveVar(userProfileVar);
  const client = useApolloClient();
  const persistor = new CachePersistor({
    cache: client.cache,
    storage: window.localStorage,
  });

  const history = useHistory();

  const checkImpersonateOrAdmin = () => {
    if (user?.isAdmin || localStorage.getItem('proxy-user-id')) return true;
    return false;
  };

  const handleClick = async (e) => {
    isLoggedInVar(false);
    userProfileVar('');
    userVar('');
    proposalsVar('');
    utils.deleteCookie('instanceId');
    await client.clearStore();
    await persistor.purge();
    localStorage.clear();
    history.push(Path.LOGIN);
  };

  const mismatchMenu = (
    <Menu className="account-menu">
      <Menu.Item onClick={handleClick}>
        <Space size="middle">
          Sign out <SignOutIcon />
        </Space>
      </Menu.Item>
    </Menu>
  );

  const menuProfile = (
    <Menu
      className="account-menu"
      onClick={(e) => {
        const path = e.key;
        if (path === 'admin') {
          if (!user?.isAdmin) {
            const adminData = adminVar();
            if (adminData !== null) {
              userVar(adminVar());
              userProfileVar(adminProfileVar());
              adminVar(null);
              adminProfileVar(null);
              localStorage.removeItem('proxy-token');
              localStorage.removeItem('proxy-user-id');
            }
          }
          return history.push(Path.ADMIN);
        }

        if (isLocked && LOCKED_PAGES.includes(path)) {
          return;
        }
        history.push(path);
      }}>
      {checkImpersonateOrAdmin() && <Menu.Item key="admin">Admin</Menu.Item>}

      <Menu.Item key={Path.PROFILE}>Profile</Menu.Item>
      {!teamId && <Menu.Item key={Path.PLANS}>Plans</Menu.Item>}
      {!teamId && (
        <Menu.Item key={Path.USERSTEAM} className={isLocked ? 'disabled' : ''}>
          Users
        </Menu.Item>
      )}
      <Menu.Item key={Path.ACTIVITY} className={isLocked ? 'disabled' : ''}>
        Activity
      </Menu.Item>
      {!teamId && <Menu.Item key={Path.AFFILIATE}>Affiliate Program</Menu.Item>}
      <Menu.Divider />

      <Menu.Item onClick={handleClick}>
        <Space size="middle">
          Sign out <SignOutIcon />
        </Space>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={window.location.pathname === Path.INVALID_CONNECTION ? mismatchMenu : menuProfile}
      placement="topRight"
      overlayClassName="account-menu-wrapper"
      trigger="click">
      <div className="user-avatar">
        {profile?.image ? (
          <Avatar src={profile.image} size={40} />
        ) : (
          <Avatar size={40}>{getUserShortName({ name: profile?.name })}</Avatar>
        )}
      </div>
    </Dropdown>
  );
};

AccountMenu.propTypes = {
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  isLocked: PropTypes.bool,
};

AccountMenu.defaultProps = {
  user: '',
  isLocked: false,
};

export default AccountMenu;
